import React, { Component } from 'react';
import { withFormik } from 'formik';
import { errorCallback, successCallback } from '../../../util/Auxiliary';
import { saveLead } from '../../../service/Lead';
import * as Yup from 'yup';

document.body.style = 'background: #e5e9ec';

class MyLogin extends Component {

	state = {
		FirstName: '',
		LastName: '',
		Email: '',
		CompanyName: '',
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			touched,
			errors,
		} = this.props;

		return (
			<div className="container">
				<div className="row login-container">
					<div className="col-md-5 offset-md-1">
						<p><img className="img-fluid" src={'/assets/img/ptpaImage.png'} alt="ptpaImage" /></p>
						<p className="col no-padding ptpa-image-text">
							NOTE: This area is NOT public. Please ensure that once you login, you confirm your your profile with PTPA in order to be able to submit products for review.
               			</p>
						<p>
							© 2018 PTPA Media Inc. All rights reserved.
                		</p>
					</div>
					<div className="col-md-5">
						<div className="row">
							<form id="couponForm" className="animated fadeIn validate" onSubmit={handleSubmit}>
								<div className="form-group col-md-10">
									<h6 className="font-weight-bold">
										<b>
											Welcome to the PTPA Media Business Login
										</b>
									</h6>
									<p>
										In order to begin your submmission process and access your private PTPA media dashboard, please fill out the form below.
									</p>
								</div>
							</form>
						</div>
						<form className="animated fadeIn validate" id="loginPasswordForm" onSubmit={handleSubmit}>
							<div className="row">
								<div className="form-group col-md-5">
									<input className="form-control" id="FirstName" placeholder="First Name" type="text"
										value={values.FirstName} onChange={handleChange} onBlur={handleBlur} />
									{errors.FirstName && touched.FirstName && <span className="text-danger">{errors.FirstName}</span>}
								</div>
								<div className="form-group col-md-5">
									<input className="form-control" id="LastName" placeholder="Last Name" type="text"
										value={values.LastName} onChange={handleChange} onBlur={handleBlur} />
									{errors.LastName && touched.LastName && <span className="text-danger">{errors.LastName}</span>}
								</div>
							</div>
							<div className="row">
								<div className="form-group col-md-5">
									<input className="form-control" id="CompanyName" placeholder="Company Name" type="text"
										value={values.CompanyName} onChange={handleChange} onBlur={handleBlur} />
									{errors.CompanyName && touched.CompanyName && <span className="text-danger">{errors.CompanyName}</span>}
								</div>
								<div className="form-group col-md-5">
									<input className="form-control" id="Email" placeholder="Your e-mail" type="email"
										value={values.Email} onChange={handleChange} onBlur={handleBlur} />
									{errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
								</div>
								<div className="form-group col-md-5">
									<div className="checkbox check-success">
										<button type="submit" className="form-control btn btn-primary btn-cons pull-left"><i className="icon-ok"></i>Create my account</button>
									</div>
								</div>
								<div className="form-group col-md-5">
									<div className="checkbox check-success">
										<a href="/Client/Login" className="form-control btn btn-set btn-cons pull-left"><i className="icon-ok"></i>Already have account?</a>
									</div>
								</div>

							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		Email: Yup.string()
			.required('Email is required!')
			.email('Invalid email address'),
	}),

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	},

	handleSubmit(values) {

		values.StatusContact = 0;

		if (values.FirstName === '' || values.LastName === '' || values.Email === '' || values.CompanyName === '') {
			errorCallback("fillallfields");
		} else {
			return saveLead(values).then(res => {
				if (res.ok) {
					successCallback("success");
					//TODO reset fields
				} else {
					errorCallback("error");
				}
			});
		}
	}

});

const MyEnhancedForm = formikEnhancer(MyLogin);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, FirstName: '', LastName: '', Email: '', CompanyName: '' };

class ManufacturerLogin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema),
			isRemoving: this.props.isRemoving
		}
	}

	render() {
		return (
			<div>
				<MyEnhancedForm model={this.state.model} />
			</div>
		);
	}
}

export default ManufacturerLogin;