import React, { Component } from 'react';

import Content from '../Content';

class Container extends Component {

	render() {
		return (
			<div className="lazyra-page-container row-fluid bg-white page-container-website">
				<Content/>
			</div>
		);

	}
}

export default Container;
