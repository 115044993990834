import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert'

import { getSystemMessage } from '../../../service/SystemMessage';
import { forgotPassword } from '../../../service/ManufacturerContact';

class MyLogin extends Component {
	
	state = {
		Email: '',
	}

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			touched,
			errors,
		} = this.props;

		return (
			<div className="container">
				<div className="row login-container">
					<div className="col-md-5 offset-md-1">
						<p><img className="img-fluid" src={'/assets/img/ptpaImage.png'} alt="ptpaImage"/></p>
						<p className="col no-padding ptpa-image-text">
							NOTE: This area is NOT public. Please ensure that once you login, you confirm your your profile with PTPA in order to be able to submit products for review.
               			</p>
						<p>
							© 2018 PTPA Media Inc. All rights reserved.
                		</p>
					</div>
					<div className="col-md-5">
						<div className="row">
							<div className="form-group col-md-10">
								<h3 className="font-weight-bold">
									<b>
										Forgot your password? Don't worry!
									</b>
								</h3>
								<p>
									We will send to your email the instruction to reset your password.
									</p>
							</div>
						</div>
						<form className="animated fadeIn validate" id="loginPasswordForm" onSubmit={handleSubmit}>
							<div className={touched.Email && errors.Email ? 'form-group has-error' : 'form-group'}>
								<label className="form-label" htmlFor="Email">Email address *</label>
								<div className="input-width-icon right">
									<input type="Email" id="Email" className="form-control" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
								</div>
								{errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
							</div>

							<div className="pull-left">
								<button className={isSubmitting ? 'btn btn-primary btn-cons disabled' : 'btn btn-primary btn-cons'} disabled={isSubmitting} >{!isSubmitting ? 'Reset password' : 'Resetting'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}


const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		Email: Yup.string()
			.required('Email is required!')
			.email('Invalid email address'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values).then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (res.ok) {
					getSystemMessage("forgetpasswordsendemail").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Notice",
								text: json,
								icon: "success",
							})
						});

				} else {
					getSystemMessage("error").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Notice",
								text: json,
							})
						});
				}
				setSubmitting(false);
			})
			.catch((error) => {
			});
	},
});

const MyEnhancedForm = formikEnhancer(MyLogin);

const now = new Date();

const baseSchema = { Created: now, Updated: now };

const schema = { Id: 0, Email: '' };

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: Object.assign(schema, baseSchema),
		}

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(values) {
		var data = {};
		data = { Email: values.Email };

		return forgotPassword(data).catch(console.log);
	}

	render() {
		return (
			<div>
				<MyEnhancedForm model={this.state.model} onSave={this.handleSave} />
			</div>
		);
	}
}

export default Login;