import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import * as MyConstantsFile from '../../../util/MyConstantsFile';

import Product from '../Product/index';
import Category from '../Categories/index';
import ProductCategory from '../ProductCategory/index';
import SurveyNewMembers from '../SurveyNewMembers';
import SurveyNewMembers2 from '../SurveyNewMembers/Survey2';
import SurveyPhilFacebook from '../SurveyNewMembers/SurveyPhilFacebook';
import NoMatch from '../../Expresso/_ComponentHelper/NoMatch';

class Content extends Component {
  render() {
    return (
      <div className="site-inner">
        <div className="lazyra-page-content bg-white">
          <div id="portlet-config" className="modal hide">
            <div className="modal-header">
              <button data-dismiss="modal" className="close" type="button" />
              <h3>Widget Settings</h3>
            </div>
            <div className="modal-body"> Widget settings form goes here </div>
          </div>
          <div className="clearfix" />
        </div>
        <div>
          <Switch>
            <Route exact path={MyConstantsFile.APP_WEBSITE_PRODUCT_PATH} render={props => <Product {...props} />} />
            <Route exact path={MyConstantsFile.APP_WEBSITE_CATEGORIES_PATH} render={props => <Category {...props} />} />
            <Route exact path={MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_PATH} render={props => <ProductCategory {...props} />} />
            <Route exact path={MyConstantsFile.APP_WEBSITE_SURVEY_NEW_MEMBERS_PATH} component={SurveyNewMembers} />
            <Route exact path={`${MyConstantsFile.APP_WEBSITE_SURVEY_NEW_MEMBERS_PATH}/Toddler`} component={SurveyNewMembers2} />
            <Route exact path={`/Sampling`} component={SurveyPhilFacebook} />
            <Route render={props => <NoMatch {...props} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Content;
