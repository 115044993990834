import React, { Component } from 'react';
import moment from 'moment';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { STORAGE_URL } from '../../../util/MyConstantsFile';
import youtubeApi from 'util/Youtube';

class AliceCarouselComponent extends Component {
  state = {
    allProductPhotosSlider: [],
    thumbItem: []
  };

  async componentDidMount() {
    const { productImages, imagePath, videoId } = this.props;

    const allProductPhotosSlider = productImages.map((item, i) => (
      <div key={`key-${i}`} className="text-center">
        <img
          className="img-fluid wp-post-image"
          style={{ width: 'auto', maxHeight: '350px' }}
          src={`${STORAGE_URL}${imagePath}/${item}?${moment(new Date()).format('YYYYMMDDHHmmss')}`}
          alt="product"
        />
      </div>
    ));

    const thumbItem = productImages.map((item, i) => (
      <li style={{ display: 'inline' }} key={i} onClick={() => this.Carousel._onDotClick(i)}>
        <img
          className="img-fluid"
          style={{ width: '13%' }}
          src={`${STORAGE_URL}${imagePath}/${item}?${moment(new Date()).format('YYYYMMDDHHmmss')}`}
          alt="product thumbnail"
        />
      </li>
    ));

    if (videoId) {
      allProductPhotosSlider.push(this.addYoutube(videoId));
      const thumb = await this.addYoutubeThumbnail(videoId);
      thumbItem.push(thumb);
    }

    this.setState({
      allProductPhotosSlider,
      thumbItem
    });
  }

  addYoutube(videoId) {
    videoId = videoId.trim();
    const videoSrc = `https://www.youtube.com/embed/${videoId}?rel=0`;
    return (
      <div key={videoId} className="text-center">
        <iframe
          width="100%"
          height="400"
          src={videoSrc}
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="video player"
        />
      </div>
    );
  }

  addYoutubeThumbnail = async videoId => {
    const response = await youtubeApi.get('/videos', {
      params: {
        id: videoId
      }
    });

    const thumbnail = response.data.items[0].snippet.thumbnails.default.url;

    return (
      <li style={{ display: 'inline' }} key={videoId} onClick={() => this.Carousel._onDotClick(videoId)}>
        <img className="img-fluid" style={{ width: '13%' }} src={thumbnail} alt="product thumbnail" />
      </li>
    );
  };

  render() {
    const { allProductPhotosSlider, thumbItem } = this.state;

    return (
      <div className="text-center">
        <AliceCarousel
          ref={el => (this.Carousel = el)}
          items={allProductPhotosSlider}
          dotsDisabled={true}
          buttonsDisabled={true}
          duration={400}
          autoPlay={true}
          fadeOutAnimation={true}
          autoPlayInterval={3000}
          autoPlayDirection="ltr"
          responsive={{
            0: { items: 1 },
            600: { items: 1 },
            1024: { items: 1 }
          }}
          disableAutoPlayOnAction={true}
        />
        <ul style={{ listStyleType: 'none', cursor: 'pointer' }}>{thumbItem}</ul>
      </div>
    );
  }
}

export default AliceCarouselComponent;
