/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './index.css';
const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <div className="header navbar navbar-inverse">
      {isSafari() && <div className="browser-compatibility">BROWSER WARNING: Please use Chrome or FireFox.  This administration panel is not optimized for Safari browser.</div>}
        <div className="navbar-inner" style={{ height: '60px' }}>
          <div className="header-seperation">
            {this.state.width < 767 && (
              <ul className="nav pull-left notifcation-center visible-xs visible-sm">
                <li className="dropdown">
                  <a href="#main-menu" data-webarch="toggle-left-side">
                    <i className="material-icons">menu</i>
                  </a>
                </li>
              </ul>
            )}
            <img src="/assets/img/PTPA-Media-LogoMark-White-Text.png" className="logo" alt="" style={{ marginLeft: '75px' }} />
          </div>
          <div className="header-quick-nav">
            <div className="pull-left">
              <ul className="nav quick-section">
                <li className="quicklinks">
                  <a id="layout-condensed-toggle">
                    <div className="iconset top-menu-toggle-dark" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
