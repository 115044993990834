/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Auth from '../../../util/Auth';

import * as MyConstantsFile from '../../../util/MyConstantsFile'
import { getPaymentSummary } from '../../../service/PaymentInformation';

class ManufacturerSideBar extends Component {

	state = {
		openPayment: null
	}

	componentDidMount() {
		if (Auth.getTypeUser() === "manufacturer" && Auth.isUserAuthenticated())
			getPaymentSummary()
				.then(res => res.json()
					.then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({
						openPayment: json.ProspectId
					});
				})
				.catch(console.log);
	}

	onClickLogout = () => {
		const { cookies } = this.props;

		cookies.remove('gtmc', { path: '/' })
		cookies.remove('token', { path: '/' })

		Auth.deauthenticateUser();
		Auth.deauthenticateCoupon();

		window.location.href = "/client/login";
	}

	render() {

		return (
			<div className="page-sidebar " id="main-menu">
				<div className="page-sidebar-wrapper scrollbar-dynamic" id="main-menu-wrapper">
					<ul>
						{/* <li>
							<Link to="/"><i className="fa fa-home"></i> <span className="title">Dashboard</span></Link>
						</li> */}
						<li><a href="#"> <i className="fa fa-user"></i> <span className="title">My Profile</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_PROFILE_PATH}>Edit</Link></li></ul></li>
						<li><a href="#"> <i className="fas fa-shopping-cart"></i> <span className="title">Payment</span> <span className="arrow">{this.state.openPayment && <span style={{ marginTop: '6px' }} className="label label-important bubble-only pull-right"></span>}</span></a><ul className="sub-menu">
							{this.state.openPayment && <li><Link to={MyConstantsFile.APP_ADMIN_PAYMENTINFORMATION_PATH}>Make Payment<span style={{ marginTop: '4px', marginRight: '20px' }} className="label label-important bubble-only pull-right"></span></Link></li>}</ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">Product</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li>
							<Link to={MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_PATH}>Manager</Link></li></ul></li>
						{/* <li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">Coupon</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/Coupon">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">GenderTarget</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/GenderTarget/add">Add</Link></li><li><Link to="/GenderTarget">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">HeardAbout</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/HeardAbout/add">Add</Link></li><li><Link to="/HeardAbout">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">Manufacturer</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/Manufacturer/add">Add</Link></li><li><Link to="/Manufacturer">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">Package</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/Package/add">Add</Link></li><li><Link to="/Package">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">PackageItem</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/PackageItem/add">Add</Link></li><li><Link to="/PackageItem">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">PaymentInformation</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/PaymentInformation/add">Add</Link></li><li><Link to="/PaymentInformation/add/card">Add Card</Link></li><li><Link to="/PaymentInformation">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">Product</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/Product/add">Add</Link></li><li><Link to="/Product">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">Prospect</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/Prospect/add">Add</Link></li><li><Link to="/Prospect">Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">State</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/State/add">Add</Link></li><li><Link to="/State">Manager</Link></li></ul></li> */}
						<li><a onClick={this.onClickLogout} style={{ cursor: 'pointer' }}> <i className="fa fa-power-off"></i> <span className="title">Log out</span>  </a></li>
					</ul>
					<div className="clearfix"></div>
				</div>
			</div>
		);
	}
}

export default withCookies(ManufacturerSideBar);
