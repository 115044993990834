export default class Auth {
  /**
   * Authenticate an user. Save a token string in Session Storage
   *
   * @param {string} token
   */
  static authenticateUser(token) {
    sessionStorage.setItem('token', token);
  }

  /**
   * Check if an user is authenticated - check if a token is saved in Session Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return sessionStorage.getItem('token') !== null;
  }

  /**
   * Deauthenticate an user. Remove a token from Session Storage.
   */
  static deauthenticateUser() {
    sessionStorage.removeItem('token');
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */
  static getToken() {
    return sessionStorage.getItem('token');
  }

  static deauthenticateCoupon() {
    sessionStorage.removeItem('coupon');
    sessionStorage.removeItem('typeUser');
  }

  static authenticateCoupon(coupon) {
    sessionStorage.setItem('coupon', coupon);
  }

  static setTypeUser(type) {
    sessionStorage.setItem('typeUser', type);
  }

  static getCoupon() {
    return sessionStorage.getItem('coupon');
  }


  static getTypeUser() {
    return sessionStorage.getItem('typeUser');
  }


  static isCouponAuthenticated() {
    return sessionStorage.getItem('coupon') !== null;
  }

}

