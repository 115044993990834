import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';
import ShowMoreText from 'react-show-more-text';
import {BASE_URL} from '../../../util/MyConstantsFile';

class ProductInformation extends Component {
  htmlDecode = input => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  };

  render() {
    const { product, productRating } = this.props;

    return (
      <div className="la-custom-pright">
        <div className="summary entry-summary">
          <h1 className="product_title entry-title" style={{ fontFamily: 'Poppins' }}>
            {product.ProductName}
          </h1>

          <div className="product_meta-top">
            <StarRatings
              id="Rating"
              className="form-control"
              rating={productRating}
              starRatedColor="orange"
              numberOfStars={5}
              name="rating"
              starDimension="25px"
              starSpacing="2px"
            />
          </div>

          {product.PriceStarts !== '0.00' && (
            <div className="single-price-wrapper">
              <p className="price">
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol" style={{ fontFamily: 'Poppins' }}>
                    $
                    {product.PriceRetail !== null && product.PriceRetail !== '0.00' && product.PriceRetail !== ''
                      ? product.PriceRetail
                      : product.PriceEnds
                      ? `${product.PriceStarts} ~ $${product.PriceEnds}`
                      : `${product.PriceStarts}`}
                  </span>
                </span>
              </p>
            </div>
          )}

          <a
            href={product.Website.indexOf('http') > -1 ? product.Website : 'http://' + product.Website}
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: 'pointer', backgroundColor: '#343538', fontFamily: 'Poppins' }}
            className="single_add_to_cart_button button alt"
          >
            Learn More
          </a>

          <div className="clearfix" />

          <div className="woocommerce-product-details__short-description">
            <ShowMoreText lines={4} more="Show more" less="Show less" anchorclassName="text-warning">
              <div style={{ fontFamily: 'Poppins' }} dangerouslySetInnerHTML={{ __html: this.htmlDecode(product.ProductDescription) }} />
            </ShowMoreText>
          </div>

          <div className="clearfix" />

          <div className="product_meta">
            <span className="posted_in" style={{ fontFamily: 'Poppins' }}>
              Categories:
              <a
                style={{ cursor: 'pointer', fontFamily: 'Poppins' }}
                href={BASE_URL.product+'products/' + product.ProductCategory[0].Category.UrlFriendly}
              >
                <span
                  className="vc_tta-title-text"
                  style={{ letterSpacing: '1px', fontFamily: 'Poppins' }}
                >{` ${product.ProductCategory[0].Category.CategoryName}`}</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductInformation;
