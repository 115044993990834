import React, { Component } from 'react';

import ProductRelatedItemComponent from './ProductRelatedItemComponent';
import { getProductRelatedList } from '../../../service/Product';

class ProductRelatedContainer extends Component {

    state = {
        productRelatedList: [],
    }

    componentDidMount = () => {
        const obj = { Id: this.props.productId, QuantityPerPage: 4 }
        getProductRelatedList(obj)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                this.setState({
                    productRelatedList: json,
                })
            });
    }


    render() {
        return (
            <div className="custom-product-wrap related" >
                <div className="custom-product-ul">
                    <div className="row block_heading">
                        <div className="col-xs-12 align-content-center">
                            <h3 className="block_heading--title" style={{ fontFamily: 'Poppins' }}>Related Products</h3>
                            <div className="block_heading--subtitle"></div>
                        </div>
                    </div><div className="row">
                        <div className="col-xs-12">
                            <ul className="products grid-items products-grid grid-space-default products-grid-1 xlg-grid-4-items lg-grid-4-items md-grid-3-items sm-grid-2-items xs-grid-1-items mb-grid-1-items">
                                <ProductRelatedItemComponent productRelatedList={this.state.productRelatedList} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductRelatedContainer;