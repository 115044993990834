import React from 'react';
import moment from 'moment';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

const PopularProductItemListContainer = props => {
    return (
        props.popularProductList.map((item) => (
            <li key={item.Id} className="product_item grid-item product thumb-has-effect prod-rating-off">
                <div className="product_item--inner clearfix">
                    <div className="product_item--thumbnail" style={{ minHeight: '79px' }}>
                        <div className="product_item--thumbnail-holder">
                            <a href={props.redirectTo + '/' + item.Product.UrlFriendly} style={{ cursor: 'pointer' }} >
                                <img src={`${STORAGE_URL}product/${item.Product.LargeImage}?${moment(new Date()).format('YYYYMMDDHHmmss')}`} style={{ minHeight: '79px' }} className="wp-post-image" alt={item.Product.ProductName} />
                            </a>
                        </div>
                    </div>
                    <div className="product_item--info">
                        <h3 className="product_item--title">
                            <a href={`${props.redirectTo}/${item.Product.UrlFriendly}`} style={{ cursor: 'pointer' }}>{item.Product.ProductName}</a>
                        </h3>
                        <span
                            onClick={() => props.onManufactureSearch(item.Product.Manufacturer.Id)}
                            style={{ cursor: 'pointer' }} >
                            <span className="sku">{'MFG: ' + item.Product.Manufacturer.CompanyName}</span>
                        </span>
                    </div>
                </div>
            </li>
        ))
    )
};

PopularProductItemListContainer.defaultProps = {
    popularProductList: [],
    redirectTo: '',
}

export default PopularProductItemListContainer;
