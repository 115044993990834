import React, { Component } from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

import CategoryListContainer from '../_Components/CategoryListContainer';
import ProductAsideComponent from '../_Components/ProductAsideComponent';
import { getPerUrlFriendly } from '../../../service/Category';
import { getProductWebSiteCategory, getAllProductWebSite } from '../../../service/Product';
import ProductContainer from '../_Components/ProductContainer';
import LoadingPage from '../_Components/LoadingPage';
import SearchProductComponent from '../_Components/SearchProductComponent';
import PopularProductListContainer from '../_Components/PopularProductListContainer';
import ProductItemListComponent from '../_Components/ProductItemListComponent';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class ProductCategory extends Component {

    state = {
        product: null,
        categoryImage: '',
        iconCategory: '',
        categoryName: '',
        friendlyURL: this.props.match.params.friendlyURL,
        searchTerm: '',
        page: this.props.match.params.page ? parseInt(this.props.match.params.page) - 1 : 0,
        pageSize: 20,
        filtered: [],
        data: [],
        quantityProducts: null,
        pages: null,
        hover: false,
        styleLoading: { opacity: '0.3' },
        loading: true,
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            const url = location.pathname.toLowerCase().replace('/products/', '').split('/')
            this.setState({
                friendlyURL: url[0],
                page: url.length > 1 ? parseInt(url[1]) - 1 : 0,
                loading: true,
            }, () => {
                if (this.state.friendlyURL.indexOf("search-") > -1 || this.state.friendlyURL.indexOf("manufacturer-") > -1)
                    this.setState({
                        categoryImage: '',
                        iconCategory: '',
                        categoryName: '',
                    })
                this.fetchData(this.state)
            })
        })
    }

    componentDidMount = () => {
        if (this.state.friendlyURL && this.state.friendlyURL.indexOf("search-") === -1 && this.state.friendlyURL.indexOf("manufacturer-") === -1)
            getPerUrlFriendly(this.state.friendlyURL)
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    if (res.ok)
                        this.setState({
                            categoryImage: `${STORAGE_URL}category/${json.CategoryLargeImage}?${moment(new Date()).format('YYYYMMDDHHmmss')}`,
                            iconCategory: `${STORAGE_URL}category/${json.IconCategory}?${moment(new Date()).format('YYYYMMDDHHmmss')}`,
                            categoryName: json.CategoryName,
                        })
                })

        this.fetchData(this.state)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.friendlyURL && this.state.friendlyURL.indexOf("search-") === -1 && this.state.friendlyURL.indexOf("manufacturer-") === -1 &&
            prevState.friendlyURL !== this.state.friendlyURL) {
            getPerUrlFriendly(this.state.friendlyURL)
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    if (res.ok)
                        this.setState({
                            categoryImage: `${STORAGE_URL}category/${json.CategoryLargeImage}?${moment(new Date()).format('YYYYMMDDHHmmss')}`,
                            iconCategory: `${STORAGE_URL}category/${json.IconCategory}?${moment(new Date()).format('YYYYMMDDHHmmss')}`,
                            categoryName: json.CategoryName,
                            page: 0,
                        })
                })
        }
    }

    fetchData = (state, instance) => {
        window.scroll(0, 0)
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.requestData(
            this.state,
            state.pageSize,
            state.page,
            state.filtered
        ).then(res => {
            // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
            this.setState({
                data: res.rows,
                pages: res.pages,
                quantityProducts: res.quantityProducts,
                loading: false,
            });
        });
    }

    requestData = (currentState, pageSize, page, filtered) => {
        return new Promise((resolve, reject) => {
            // You can retrieve your data however you want, in this case, we will just use some local data.
            var tableModal = {
                QuantityPerPage: pageSize,
                Page: page + 1,
            }

            if (this.state.friendlyURL && this.state.friendlyURL.indexOf("search-") === -1 && this.state.friendlyURL.indexOf("manufacturer-") === -1) {
                tableModal.CategoryUrlFriendly = currentState.friendlyURL

                this.setState({ searchTerm: currentState.friendlyURL })

                getProductWebSiteCategory(tableModal)
                    .then(res => res.json().then(json => ({ res, json })))
                    .then(({ res, json }) => {

                        // You must return an object containing the rows of the current page, and optionally the total pages number.
                        const result = {
                            rows: json.Item2,
                            pages: Math.ceil(json.Item1 / pageSize),
                            quantityProducts: json.Item1,
                        };
                        resolve(result);
                    });
            } else {

                if (this.state.friendlyURL && this.state.friendlyURL.indexOf("manufacturer-") > -1) {
                    tableModal.ManufacturerId = this.state.friendlyURL.substring(13)
                    this.setState({ searchTerm: this.state.friendlyURL.substring(13) })
                }

                if (this.state.friendlyURL && this.state.friendlyURL.indexOf("search-") > -1) {
                    tableModal.SearchTearm = this.state.friendlyURL.substring(7)
                    this.setState({ searchTerm: this.state.friendlyURL.substring(7) })
                }

                getAllProductWebSite(tableModal)
                    .then(res => res.json().then(json => ({ res, json })))
                    .then(({ res, json }) => {

                        // You must return an object containing the rows of the current page, and optionally the total pages number.
                        const result = {
                            rows: json.Item2,
                            pages: Math.ceil(json.Item1 / pageSize),
                            quantityProducts: json.Item1,
                        };
                        resolve(result);
                    })
            }
        });
    };

    onCallNewUrl = (path) => {
        window.scrollTo(0, 0)
        this.props.history.push(path);
    }

    onPageClick = (data) => {
        this.onCallNewUrl(`${MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH}/${this.state.friendlyURL}/${parseInt(data.selected) + 1}`)
    };

    onCategoryClick = (childrenProps) => {
        this.onCallNewUrl(`${MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH}/${childrenProps}/1`)
    };

    onSearchProduct = (childrenProductSearch) => {
        this.onCallNewUrl(`${MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH}/search-${childrenProductSearch}/1`)
    };

    onManufactureSearch = (childrenManufactureSearch) => {
        this.onCallNewUrl(`${MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH}/manufacturer-${childrenManufactureSearch}/1`)
    }

    renderProductList = () => {
        return this.state.data.map(item =>
            <div key={item.Id} className="col-md-3 product_item grid-item thumb-has-effect prod-rating-off">
                <ProductItemListComponent item={item} redirectTo={MyConstantsFile.APP_WEBSITE_PRODUCT_CALL_PATH} onManufactureSearch={this.onManufactureSearch} />
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.state.loading && <LoadingPage />}

                <ProductContainer
                    styleLoading={this.state.loading ? this.state.styleLoading : {}}
                    categoryImage={this.state.categoryImage}
                    iconCategory={this.state.iconCategory}
                    categoryName={this.state.categoryName}>

                    <ProductAsideComponent >
                        <SearchProductComponent onSearchProduct={this.onSearchProduct} />

                        <CategoryListContainer onCategoryClick={this.onCategoryClick} friendlyURL={this.state.friendlyURL} />

                        <PopularProductListContainer popularProductList={this.state.popularProductList} onManufactureSearch={this.onManufactureSearch} />
                    </ProductAsideComponent>

                    <main id="site-content" className="col-md-9 col-xs-12 site-content float-right">
                        <div className="site-content-inner">
                            <div className="lazyra-page-content">
                                <div className="wc-toolbar-container">
                                    <div className="wc-toolbar wc-toolbar-top clearfix">
                                        <div className="wc-toolbar-left">
                                            <p className="woocommerce-result-count">
                                                Showing {20 * this.state.page + 1} - {this.state.quantityProducts < 20 ? this.state.quantityProducts :
                                                    20 * (this.state.page + 1) > this.state.quantityProducts ? this.state.quantityProducts :
                                                        20 * (this.state.page + 1)} of {this.state.quantityProducts} results
                                                        </p>
                                        </div>
                                        {/* <div className="wc-toolbar-right">
                                                        <div className="wc-ordering" onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
                                                            <p>Sort by<i className={this.state.hover ? "fas fa-angle-up ml-2" : "fas fa-angle-down ml-2"}></i></p>
                                                            <ul className="sort-product-category">
                                                                <li className="active">
                                                                    <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} href="/products/food-beverage/orderby-default" >Default sorting</a>
                                                                </li>
                                                                <li>
                                                                    <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} href="/products/baby-toddler/orderby-winner-desc">Sort by latest winners</a>
                                                                </li>
                                                                <li>
                                                                    <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} href="/products/baby-toddler/orderby-price-low">Sort by price: low to high</a>
                                                                </li>
                                                                <li>
                                                                    <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} href="/products/baby-toddler/orderby-price-high">Sort by price: high to low</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> */}
                                    </div>
                                </div>

                                {this.state.data.length > 0 && <div id="la_shop_products" className="la-shop-products">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="products products-grid grid-space-default">
                                                {this.renderProductList()}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {this.state.data.length === 0 && <div id="la_shop_products" className="la-shop-products">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <h3>No results for {this.state.searchTerm}.</h3>
                                            <h6>Try checking your spelling or use more general terms</h6>
                                        </div>
                                    </div>
                                </div>}

                                {this.state.data.length > 0 && <nav className="la-pagination clearfix">

                                    <ReactPaginate
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.pages ? this.state.pages : 0}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.onPageClick}
                                        containerClassName={'page-numbers'}
                                        subContainerClassName={'page-numbers'}
                                        activeClassName={'active'}
                                        initialPage={this.state.page}
                                        forcePage={this.state.page}
                                        disableInitialCallback={true}
                                    />
                                </nav>}
                            </div>
                        </div>
                    </main>
                </ProductContainer>
            </div >
        );
    }
}

export default ProductCategory;