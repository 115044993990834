import { getSystemMessage } from '../service/SystemMessage';

export const errorCallback = message => {
  getSystemMessage(message)
    .then(res => res.json().then(json => ({ res, json })))
    .then(({ res, json }) => {
      window.swal('Notice', json, 'warning');
    });
};

export const successCallback = message => {
  getSystemMessage(message)
    .then(res => res.json().then(json => ({ res, json })))
    .then(({ res, json }) => {
      window.swal('Success', json, 'success');
    });
};

export const formatted_date = date => {
  let current_datetime = new Date(date);
  return current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate();
};
