import React, { Component } from 'react';
import { withFormik } from 'formik';
import {BASE_URL} from '../../../util/MyConstantsFile';

class Form extends Component {
  state = {
    modalStyle: window.innerWidth < 500 ? { width: '90%' } : { width: '30%' }
  };

  componentDidMount() {
    document.body.style.background = 'transparent';
  }

  render() {
    const { values, handleChange, handleBlur, handleSubmit } = this.props;

    return (
      <div className="modal fade show" id="searchBox" style={{ backgroundColor: 'transparent' }}>
        <div className="modal-dialog" style={this.state.modalStyle}>
          <div className="modal-content" style={{ width: '100%' }}>
            <div className="vc_row wpb_row vc_inner vc_row-fluid">
              <div className="wpb_column vc_column_container col-sm-12 m-0 p-0">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper">
                    <div className="la-myaccount-page" style={{ margin: '20px 50px !important' }}>
                      <div className="la-tabs">
                        <div className="la-tab-panel active" style={{ display: 'block' }}>
                          <form onSubmit={handleSubmit}>
                            <div className="woocomerce-form woocommerce-form-login login">
                              <div className="modal-header pr-0 pl-0">
                                <h3 className="modal-title bold text-dark" style={{ fontFamily: 'Poppins' }}>
                                  Search Product
                                </h3>
                              </div>
                              <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                <input
                                  type="text"
                                  className="woocommerce-Input woocommerce-Input--text input-text"
                                  name="TextSearch"
                                  id="TextSearch"
                                  value={values.TextSearch}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </p>
                              <button
                                className="woocommerce-Button button text-center form-actions bold"
                                style={{ cursor: 'pointer', fontFamily: 'Poppins' }}
                                disabled={values.TextSearch === ''}
                              >
                                SEARCH
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: props => ({ ...props.model }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props.save(values);
  }
});

const MyEnhancedForm = formikEnhancer(Form);

const schema = { TextSearch: '' };

class SearchProductFromWordPress extends Component {
  state = {
    model: Object.assign(schema)
  };

  handleSave = values => {
    window.open(BASE_URL.product+'products/search-' + values.TextSearch, '_parent');
  };

  render() {
    return <MyEnhancedForm model={this.state.model} save={this.handleSave} />;
  }
}

export default SearchProductFromWordPress;
