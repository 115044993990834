import React from 'react'

const ProductAsideComponent = (props) => {
    return (
        <aside id="sidebar_primary" className="col-md-3 col-xs-12">
            <div className="sidebar-inner">
                {props.children}
            </div>
        </aside>
    )
}

export default ProductAsideComponent;