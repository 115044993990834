import React from 'react';

const ProductEmbedComponent = ({ children }) => {
  return (
    <div className="site-main" style={{ backgroundColor: 'white' }}>
      <div className="container">
        <div className="row">
          <main id="site-content" className="col-md-12 col-xs-12 site-content">
            <div className="lazyra-page-content">
              <div className="wpb_wrapper">
                <div id="la_shop_products" className="la-shop-products">
                  <div className="row">
                    <div className="col-xs-12 p-0">{children}</div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ProductEmbedComponent;
