import React from 'react';

const ProductBannerComponent = props => {
    return (
        <div className="text-white"
            style={{
                position: 'relative', width: '100%', height: '0', paddingBottom: '10%', textAlign: 'center',
                backgroundImage: `url(${props.categoryImage})`, backgroundSize: 'contain'
            }}>
            
            <div style={{ height: '100%', position: 'absolute', right: '5%' }} >

                <img className="img-fluid" src="/assets/img/PTPA-Seal-Of-Approval-Award.png"
                    style={{ height: 'inherit' }}
                    alt="Seal" />

                <img className="img-fluid" src={props.iconCategory}
                    style={{ height: 'inherit' }}
                    alt="CategoryBanner" />
            </div>

            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <h2 className="bold text-white" style={{ fontSize: '3vw', fontFamily: 'Poppins', textAlign: 'center' }}>{props.categoryName}</h2>
                {window.innerWidth > 900 && <div className="la-breadcrumbs hidden-xs mt-3">
                    <div className="la-breadcrumb-content">
                        <div className="la-breadcrumb-wrap">
                            <div className="la-breadcrumb-item">
                                <span className="la-breadcrumb-item-link is-home text-white"
                                    style={{ fontFamily: 'Poppins' }}>Home</span>
                            </div>
                            <div className="la-breadcrumb-item">
                                <div className="la-breadcrumb-item-sep">/</div>
                            </div>
                            <div className="la-breadcrumb-item">
                                <span className="la-breadcrumb-item-target text-white" style={{ fontFamily: 'Poppins' }}>Products</span>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

ProductBannerComponent.defaultProps = {
    categoryImage: '',
    styleCategoryImage: {},
    categoryName: '',
}

export default ProductBannerComponent;