import React from 'react';
import moment from 'moment';
import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

const time = moment(new Date()).format('YYYYMMDDHHmmss');

const ProductRelatedItemComponent = props => {
    return (
        <div className="row justify-content-center p-0 m-0">
            {
                props.productRelatedList.map((item) =>
                    <div key={item.Id} className="col-xs-12 col-sm-3">
                        <div className="m-1" >
                            <li className="" >
                                <div className="">
                                    <a href={`${MyConstantsFile.APP_WEBSITE_PRODUCT_CALL_PATH}/${item.UrlFriendly}`} style={{ cursor: 'pointer' }} >
                                        <div className="text-center">
                                            <img src={item.LargeImage ? `${STORAGE_URL}product/${item.LargeImage}?${time}` : ""}
                                                className="img-fluid wp-post-image" alt={item.ProductName}
                                                style={{ maxWidth: '100%', height: '250px' }}
                                            />
                                        </div>
                                        <div className="item--overlay"></div>
                                    </a>
                                </div>
                                <div className="product_item--info mt-2">
                                    <div className="product_item--info-inner">
                                        <a href={`${MyConstantsFile.APP_WEBSITE_PRODUCT_CALL_PATH}/${item.UrlFriendly}`} style={{ cursor: 'pointer' }}><span className="product_item--title" style={{ fontFamily: 'Poppins' }}><b>{item.ProductName}</b></span></a><br />
                                        <span style={{ cursor: 'pointer', fontFamily: 'Poppins' }}><span className="sku">MFG: {item.Manufacturer.CompanyName}</span></span>
                                    </div>
                                </div>
                            </li>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

ProductRelatedItemComponent.defaultProps = {
    productRelatedList: [],
}

export default ProductRelatedItemComponent;

