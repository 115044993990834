import React, { Component } from 'react'

class SearchProductComponent extends Component {

    state = { TextSearch: '' };

    handleChange = (event) => {
        this.setState({ TextSearch: event.target.value });
    }

    handleSearch = () => {
        this.props.onSearchProduct(this.state.TextSearch)
        this.setState({ TextSearch: '' })
    }

    render() {
        return (
            <div className="widget woocommerce widget_product_search">
                <h3 className="lazyra-widget-title"><span style={{ fontFamily: 'Poppins', color: '#343538', fontWeight: 'bold' }}>SEARCH PRODUCT</span></h3>
                <div className="search-form">
                    <input type="search" className="search-field" placeholder="Search entire website" value={this.state.TextSearch}
                        onChange={this.handleChange}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                this.handleSearch()
                            }
                        }} />
                    <button className="search-button" type="submit" onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                </div>
            </div>
        )
    }
}

export default SearchProductComponent;