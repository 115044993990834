import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from '../Login';
import AdminForgotPassword from '../Login/ForgotPassword';
import AdminResetPassword from '../Login/ResetPassword';
import ManufacturerLogin from '../Manufacturer/Login';
import ManufacturerLead from '../Manufacturer/Lead';
import ManufacturerForgotPassword from '../Manufacturer/ForgotPassword';
import ManufacturerResetPassword from '../Manufacturer/ResetPassword';
import App from '../App';
import AppDashboardPage from '../../Dashboard/AppDashboard/index';
import AppWebsite from '../../Website/AppWebsite/index';
import ProductWinners from 'components/Website/ProductWinners';
import SearchWordPress from 'components/Website/SearchWordPress';
import AldiProducts from 'components/Website/AldiProducts';

import * as MyConstantsFile from '../../../util/MyConstantsFile';

class AppCookies extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/Login/Forgot-Password" render={props => <AdminForgotPassword {...props} />} />
          <Route exact path="/Login/Reset-Password" render={props => <AdminResetPassword {...props} />} />
          <Route exact path="/Login" render={props => <Login {...props} />} />
          <Route path="/Client/Login" render={props => <ManufacturerLogin {...props} />} />
          <Route path="/Client/Forgot-Password" render={props => <ManufacturerForgotPassword {...props} />} />
          <Route path="/Client/Reset-Password" render={props => <ManufacturerResetPassword {...props} />} />
          <Route path="/Client/Lead" render={props => <ManufacturerLead {...props} />} />
          <Route path="/Dashboard" render={props => <AppDashboardPage {...props} />} />
          <Route path={MyConstantsFile.APP_ADMIN_PATH} render={props => <App {...props} />} />
          <Route path="/ProductWinners" render={props => <ProductWinners {...props} />} />
          <Route path="/SearchWordPress" render={props => <SearchWordPress {...props} />} />
          <Route path="/AldiProducts" render={props => <AldiProducts {...props} />} />
          <Route path="/" render={props => <AppWebsite {...props} />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default AppCookies;
