import React from 'react';
import Progress from 'react-progressbar';

const ProductShowReviewsSummaryProgress = props => {
    return (
        <div className="wpb_column vc_column_container row">
            <div className="wpb_column vc_column_container col-sm-5" style={{ fontFamily: 'Poppins' }}>{props.title}</div>

            <Progress className="mt-2 col p-0" color="orange" completed={props.progress} style={{ backgroundColor: '#cfcfcf', width: '40%', height: '10px' }} />

            <div className="wpb_column vc_column_container col-sm-1 textSummaryReview"><span style={{ fontFamily: 'Poppins' }}>{props.rate}</span></div>
        </div>
    )
}

ProductShowReviewsSummaryProgress.defaultProps = {
    title: '',
    progress: 0,
    rate: '',
}

export default ProductShowReviewsSummaryProgress;