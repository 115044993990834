import React, { Component } from 'react';
import moment from 'moment';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class CategoryImageListContainer extends Component {
  onCategoryClick = childrenProps => {
    this.props.onCategoryClick(childrenProps);
  };

  render() {
    const now = new Date();
    const time = moment(now).format('YYYYMMDDHHmmss');

    return this.props.data.map(item => (
      <div className="wpb_column vc_column_container col-sm-6 col-md-6 col-lg-4 col-xl-3" key={item.Id}>
        <div className="vc_column-inner ">
          <div className="wpb_wrapper">
            <div className="wpb_content_element la-banner-box banner-type-7 margin-bottom-15 font-weight-700 text-color-secondary">
              <div className="box-inner" onClick={() => this.onCategoryClick(item.UrlFriendly)} style={{ cursor: 'pointer' }}>
                <div className="banner--image">
                  <div className="la-lazyload-image pb-0">
                    <img
                      className="img-fluid"
                      alt={item.CategoryName}
                      src={`${STORAGE_URL}category/${item.CategoryImage}?${time}`}
                      style={{ maxWidth: '100%' }}
                    />
                  </div>
                </div>
                <div className="banner--info">
                  <div className="js-el b-title b-title1" style={{ color: '#ffffff' }}>
                    {' '}
                  </div>
                  <div className="titleCategories js-el b-title b-title2 letter-spacing--2" style={{ color: '#ffffff', minHeight: '80px' }}>
                    <h1 className="bold" style={{ color: '#ffffff', fontFamily: 'Poppins' }}>
                      {item.CategoryName}
                    </h1>
                  </div>
                  <span
                    className="banner--btn"
                    style={{ background: 'transparent', border: '2px solid #fff', color: '#ffffff', fontFamily: 'Poppins', cursor: 'pointer' }}
                    title="Explore"
                  >
                    Explore
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
}

CategoryImageListContainer.defaultProps = {
  categoryList: [],
  friendlyURL: ''
};

export default CategoryImageListContainer;
