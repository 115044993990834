import React from 'react';
import {BASE_URL} from '../../../util/MyConstantsFile';

const ProductShowContentHeader = props => {
    return (
        <ul className="tabs wc-tabs">
            <li className={props.showContent === 'Description' ? "description_tab active" : "description_tab"}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} onClick={() => { props.onShowContent('Description') }} name="readMore">Description</a>
            </li>

            <li className={props.showContent === 'MemberReviews' ? "reviews_tab active" : "reviews_tab"}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} onClick={() => { props.onShowContent('MemberReviews') }}>
                    Reviews ({props.allReviewsByProductQty + props.allTestersReview})
                </a>
            </li>

            <li className="description_tab">
                <a style={{ cursor: 'pointer', backgroundColor: '#ef5619', color: '#FFF', fontFamily: 'Poppins' }}
                    href={BASE_URL.dashboard+'Dashboard/CommunityMember/Review/' + props.friendlyURL} name="writeReview">Write a review</a>
            </li>
        </ul>
    )
}

ProductShowContentHeader.defaultProps = {
    showContent: '',
    friendlyURL: '',
    allReviewsByProductQty: 0,
    allTestersReview: 0,
}

export default ProductShowContentHeader;
