import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import * as MyConstantsFile from '../../../util/MyConstantsFile';

const AdministratorList = React.lazy(() => import('../Administrator/List'));
const AdministratorForm = React.lazy(() => import('../Administrator/Form'));
const CompanyTypesList = React.lazy(() => import('../CompanyTypes/List'));
const CompanyTypesForm = React.lazy(() => import('../CompanyTypes/Form'));
const CountryList = React.lazy(() => import('../Country/List'));
const CountryForm = React.lazy(() => import('../Country/Form'));
const CampaignList = React.lazy(() => import('../Campaign/List'));
const CampaignForm = React.lazy(() => import('../Campaign/Form'));
const CategoryList = React.lazy(() => import('../Category/List'));
const CategoryForm = React.lazy(() => import('../Category/Form'));
const CouponList = React.lazy(() => import('../Coupon/List'));
const GenderTargetList = React.lazy(() => import('../GenderTarget/List'));
const GenderTargetForm = React.lazy(() => import('../GenderTarget/Form'));
const HeardAboutList = React.lazy(() => import('../HeardAbout/List'));
const HeardAboutForm = React.lazy(() => import('../HeardAbout/Form'));
const LeadList = React.lazy(() => import('../Lead/List'));
const EvaluatorList = React.lazy(() => import('../Evaluator/List'));
const EmailList = React.lazy(() => import('../SendEmail/EmailHelper'));
const ManufacturerList = React.lazy(() => import('../Manufacturer/List'));
const ManufacturerForm = React.lazy(() => import('../Manufacturer/Form'));
const ManufacturerCompleteInformation = React.lazy(() => import('../Manufacturer/CompleteInformation'));
const ManufacturerEditProfile = React.lazy(() => import('../Manufacturer/EditProfile'));
const ManufacturerEditPassword = React.lazy(() => import('../Manufacturer/EditPassword'));
const SalesEditPassword = React.lazy(() => import('../Sales/EditPassword'));
const AdministratorEditPassword = React.lazy(() => import('../Administrator/EditPassword'));
const PackageList = React.lazy(() => import('../Package/List'));
const PackageForm = React.lazy(() => import('../Package/Form'));
const PaymentInformationCardInfo = React.lazy(() => import('../PaymentInformation/CardInfo'));
const ProductListAdmin = React.lazy(() => import('../Product/ListAdmin'));
const ProductListManufacturer = React.lazy(() => import('../Product/ListManufacturer'));
const UploadSurvey = React.lazy(() => import('../Product/UploadSurvey'));
const ProductFormManufacturer = React.lazy(() => import('../Product/FormManufacturer'));
const ProductFormAdmin = React.lazy(() => import('../Product/FormAdmin'));
const ProspectList = React.lazy(() => import('../Prospect/List'));
const ProspectForm = React.lazy(() => import('../Prospect/Form'));
const StateList = React.lazy(() => import('../State/List'));
const StateForm = React.lazy(() => import('../State/Form'));
const CommunityMemberList = React.lazy(() => import('../CommunityMember/List'));
const PaymentSummary = React.lazy(() => import('../PaymentInformation/PaymentSummary'));
const AllergyList = React.lazy(() => import('../Allergy/List'));
const AllergyForm = React.lazy(() => import('../Allergy/Form'));
const ReviewList = React.lazy(() => import('../Review/List'));
const RewardProductForm = React.lazy(() => import('../RewardProduct/Form'));
const RewardProductList = React.lazy(() => import('../RewardProduct/List'));
const SubscribeForm = React.lazy(() => import('../Subscribe/Form'));
const SubscribeList = React.lazy(() => import('../Subscribe/List'));
const RewardForm = React.lazy(() => import('../Reward/Form'));
const RewardList = React.lazy(() => import('../Reward/List'));
const PointFeatureList = React.lazy(() => import('../PointFeature/List'));
const PointFeatureForm = React.lazy(() => import('../PointFeature/Form'));
const EducationGroupList = React.lazy(() => import('../EducationGroup/List'));
const EducationGroupForm = React.lazy(() => import('../EducationGroup/Form'));
const EthnicityList = React.lazy(() => import('../Ethnicity/List'));
const EthnicityForm = React.lazy(() => import('../Ethnicity/Form'));
const IncomeGroupList = React.lazy(() => import('../IncomeGroup/List'));
const IncomeGroupForm = React.lazy(() => import('../IncomeGroup/Form'));
const MaritalStatusList = React.lazy(() => import('../MaritalStatus/List'));
const MaritalStatusForm = React.lazy(() => import('../MaritalStatus/Form'));
const WorkStatusGroupList = React.lazy(() => import('../WorkStatusGroup/List'));
const WorkStatusGroupForm = React.lazy(() => import('../WorkStatusGroup/Form'));
const AgeTargetList = React.lazy(() => import('../AgeTarget/List'));
const AgeTargetForm = React.lazy(() => import('../AgeTarget/Form'));
const CallToActionList = React.lazy(() => import('../CallToAction/List'));
const CallToActionForm = React.lazy(() => import('../CallToAction/Form'));
const IndicateActivityList = React.lazy(() => import('../IndicateActivity/List'));
const IndicateActivityForm = React.lazy(() => import('../IndicateActivity/Form'));
const NotificationPreferenceList = React.lazy(() => import('../NotificationPreference/List'));
const NotificationPreferenceForm = React.lazy(() => import('../NotificationPreference/Form'));
const TaxList = React.lazy(() => import('../Tax/List'));
const TaxForm = React.lazy(() => import('../Tax/Form'));
const SocialMediaAddMission = React.lazy(() => import('../MomSquad/AddMission'));
const SocialMediaListMission = React.lazy(() => import('../MomSquad/ListMission'));
const SocialMediaApprovePosting = React.lazy(() => import('../MomSquad/ApprovePosting'));
const SocialMediaApproveMomSquad = React.lazy(() => import('../MomSquad/ApproveMomSquad'));
const RemoveMomSquad = React.lazy(() => import('../MomSquad/RemoveMomSquad'));
const SurveyTemplateList = React.lazy(() => import('../SurveyTemplate/List'));
const SurveyTemplateForm = React.lazy(() => import('../SurveyTemplate/Form'));
const DisplaySurvey = React.lazy(() => import('../SurveyTemplate/DisplaySurvey'));
const ListSurveyPerProduct = React.lazy(() => import('../SurveyAnswer/ListSurveyPerProduct'));
const EditAnswerSurvey = React.lazy(() => import('../SurveyAnswer/EditAnswerSurvey'));
const ManufacturerSurveyResult = React.lazy(() => import('../ManufacturerSurveyResult/SurveyResultProduct'));
const ContactEdit = React.lazy(() => import('../Manufacturer/ContactEdit'));
const SalesEditProfile = React.lazy(() => import('../Sales/EditProfile'));
const AdministratorEditProfile = React.lazy(() => import('../Administrator/EditProfile'));
const NoMatch = React.lazy(() => import('../../Expresso/_ComponentHelper/NoMatch'));

class Content extends Component {
  render() {
    return (
      <div className="page-content">
        <div id="portlet-config" className="modal hide">
          <div className="modal-header">
            <button data-dismiss="modal" className="close" type="button" />
            <h3>Widget Settings</h3>
          </div>
          <div className="modal-body"> Widget settings form goes here </div>
        </div>
        <div className="clearfix" />
        <div className="content">
          <Suspense fallback={<div />}>
            <Switch>
              {/* <Route exact path="/" render={(props) => (<h1>Dashboard</h1>) {...props} />} /> */}
              <Route exact path={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_PATH} render={props => <AdministratorList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_ADD_PATH} render={props => <AdministratorForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_EDIT_PATH} render={props => <AdministratorForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_COMPANYTYPES_PATH} render={props => <CompanyTypesList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_COMPANYTYPES_ADD_PATH} render={props => <CompanyTypesForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_COMPANYTYPES_EDIT_PATH} render={props => <CompanyTypesForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_COUNTRY_PATH} render={props => <CountryList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_COUNTRY_ADD_PATH} render={props => <CountryForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_COUNTRY_EDIT_PATH} render={props => <CountryForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_COUPON_PATH} render={props => <CouponList {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_GENDERTARGET_PATH} render={props => <GenderTargetList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_GENDERTARGET_ADD_PATH} render={props => <GenderTargetForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_GENDERTARGET_EDIT_PATH} render={props => <GenderTargetForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_CAMPAIGN_PATH} render={props => <CampaignList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CAMPAIGN_ADD_PATH} render={props => <CampaignForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CAMPAIGN_EDIT_PATH} render={props => <CampaignForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_CATEGORY_PATH} render={props => <CategoryList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CATEGORY_ADD_PATH} render={props => <CategoryForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CATEGORY_EDIT_PATH} render={props => <CategoryForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_HEARDABOUT_PATH} render={props => <HeardAboutList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_HEARDABOUT_ADD_PATH} render={props => <HeardAboutForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_HEARDABOUT_EDIT_PATH} render={props => <HeardAboutForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_LEAD_PATH} render={props => <LeadList {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_EVALUATOR_PATH} render={props => <EvaluatorList {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_EMAIL_PATH} render={props => <EmailList {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_PATH} render={props => <ManufacturerList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_ADD_PATH} render={props => <ManufacturerForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_PATH} render={props => <ManufacturerForm {...props} />} />
              <Route
                exact
                path={MyConstantsFile.APP_ADMIN_CLIENT_COMPLETE_INFORMATION_PATH}
                render={props => <ManufacturerCompleteInformation {...props} />}
              />
              <Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_PROFILE_PATH} render={props => <ManufacturerEditProfile {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_PASSWORD_PATH} render={props => <ManufacturerEditPassword {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_SALES_EDIT_PASSWORD_PATH} render={props => <SalesEditPassword {...props} />} />

              <Route
                exact
                path={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_EDIT_PASSWORD_PATH}
                render={props => <AdministratorEditPassword {...props} />}
              />

              <Route exact path={MyConstantsFile.APP_ADMIN_PACKAGE_PATH} render={props => <PackageList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_PACKAGE_ADD_PATH} render={props => <PackageForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_PACKAGE_EDIT_PATH} render={props => <PackageForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_PAYMENTINFORMATION_PATH} render={props => <PaymentInformationCardInfo {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_PAYMENTSUMMARY_PATH} render={props => <PaymentSummary {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_PATH} render={props => <ProductListAdmin {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_PATH} render={props => <ProductListManufacturer {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_ADD_PATH} render={props => <ProductFormManufacturer {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_EDIT_PATH} render={props => <ProductFormManufacturer {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_ADD_PATH} render={props => <ProductFormAdmin {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_EDIT_PATH} render={props => <ProductFormAdmin {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_UPLOAD_SURVEY_PATH} render={props => <UploadSurvey {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_PROSPECT_PATH} render={props => <ProspectList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_PROSPECT_ADD_PATH} render={props => <ProspectForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_PROSPECT_EDIT_PATH} render={props => <ProspectForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_STATE_PATH} render={props => <StateList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_STATE_ADD_PATH} render={props => <StateForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_STATE_EDIT_PATH} render={props => <StateForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_ALLERGY_PATH} render={props => <AllergyList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_ALLERGY_ADD_PATH} render={props => <AllergyForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_ALLERGY_EDIT_PATH} render={props => <AllergyForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_COMMUNITYMEMBER_PATH} render={props => <CommunityMemberList Version={1} {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_COMMUNITYMEMBER_V2_PATH} render={props => <CommunityMemberList Version={2} {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_REVIEW_ACTIVE_PATH} render={props => <ReviewList {...props} reviewType="active" />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_REVIEW_TRASH_PATH} render={props => <ReviewList {...props} reviewType="trashed" />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_PATH} render={props => <RewardProductList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_ADD_PATH} render={props => <RewardProductForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_EDIT_PATH} render={props => <RewardProductForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_SUBSCRIBER_PATH} render={props => <SubscribeList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_SUBSCRIBER_ADD_PATH} render={props => <SubscribeForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_SUBSCRIBER_EDIT_PATH} render={props => <SubscribeForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_REWARD_PATH} render={props => <RewardList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_REWARD_ADD_PATH} render={props => <RewardForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_REWARD_EDIT_PATH} render={props => <RewardForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_POINTFEATURE_PATH} render={props => <PointFeatureList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_POINTFEATURE_ADD_PATH} render={props => <PointFeatureForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_POINTFEATURE_EDIT_PATH} render={props => <PointFeatureForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_PATH} render={props => <EducationGroupList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_ADD_PATH} render={props => <EducationGroupForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_EDIT_PATH} render={props => <EducationGroupForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_ETHNICITY_PATH} render={props => <EthnicityList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_ETHNICITY_ADD_PATH} render={props => <EthnicityForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_ETHNICITY_EDIT_PATH} render={props => <EthnicityForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_INCOMEGROUP_PATH} render={props => <IncomeGroupList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_INCOMEGROUP_ADD_PATH} render={props => <IncomeGroupForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_INCOMEGROUP_EDIT_PATH} render={props => <IncomeGroupForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_MARITALSTATUS_PATH} render={props => <MaritalStatusList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_MARITALSTATUS_ADD_PATH} render={props => <MaritalStatusForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_MARITALSTATUS_EDIT_PATH} render={props => <MaritalStatusForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_PATH} render={props => <WorkStatusGroupList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_ADD_PATH} render={props => <WorkStatusGroupForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_EDIT_PATH} render={props => <WorkStatusGroupForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_AGETARGET_PATH} render={props => <AgeTargetList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_AGETARGET_ADD_PATH} render={props => <AgeTargetForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_AGETARGET_EDIT_PATH} render={props => <AgeTargetForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_CALLTOACTION_PATH} render={props => <CallToActionList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CALLTOACTION_ADD_PATH} render={props => <CallToActionForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_CALLTOACTION_EDIT_PATH} render={props => <CallToActionForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_PATH} render={props => <IndicateActivityList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_ADD_PATH} render={props => <IndicateActivityForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_EDIT_PATH} render={props => <IndicateActivityForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_PATH} render={props => <NotificationPreferenceList {...props} />} />
              <Route
                exact
                path={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_ADD_PATH}
                render={props => <NotificationPreferenceForm {...props} />}
              />
              <Route
                exact
                path={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_EDIT_PATH}
                render={props => <NotificationPreferenceForm {...props} />}
              />

              <Route exact path={MyConstantsFile.APP_ADMIN_TAX_PATH} render={props => <TaxList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_TAX_ADD_PATH} render={props => <TaxForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_TAX_EDIT_PATH} render={props => <TaxForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_MISSION_PATH} render={props => <SocialMediaListMission {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_ADD_MISSION_PATH} render={props => <SocialMediaAddMission {...props} />} />
              <Route
                exact
                path={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_ADD_MISSION_EDIT_PATH}
                render={props => <SocialMediaAddMission {...props} />}
              />
              <Route
                exact
                path={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_MOM_SQUAD_APPROVAL_PATH}
                render={props => <SocialMediaApproveMomSquad {...props} />}
              />
              <Route
                exact
                path={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_APPROVEPOSTING_PATH}
                render={props => <SocialMediaApprovePosting {...props} />}
              />
              <Route exact path={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_REMOVE_MOM_SQUAD_PATH} render={props => <RemoveMomSquad {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_SURVEYTEMPLATE_PATH} render={props => <SurveyTemplateList {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_SURVEYTEMPLATE_ADD_PATH} render={props => <SurveyTemplateForm {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_SURVEYTEMPLATE_EDIT_PATH} render={props => <SurveyTemplateForm {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_SURVEYANSWER_PATH} render={props => <ListSurveyPerProduct {...props} />} />
              <Route exact path={MyConstantsFile.APP_ADMIN_SURVEYANSWER_EDIT_PATH} render={props => <EditAnswerSurvey {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_DISPLAYSURVEY_PATH} render={props => <DisplaySurvey {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_MANUFACTURERSURVEYRESULT_PATH} render={props => <ManufacturerSurveyResult {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_CONTACT_EDIT_PATH} render={props => <ContactEdit {...props} />} />

              <Route exact path={MyConstantsFile.APP_ADMIN_SALES_EDIT_PROFILE_PATH} render={props => <SalesEditProfile {...props} />} />

              <Route
                exact
                path={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_EDIT_PROFILE_PATH}
                render={props => <AdministratorEditProfile {...props} />}
              />

              <Route render={props => <NoMatch {...props} />} />
            </Switch>
          </Suspense>
        </div>
      </div>
    );
  }
}

export default Content;
