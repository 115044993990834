import React, { Component } from 'react';
import ProductRelatedContainer from './ProductRelatedContainer';
import ProductShowContentHeader from './ProductShowContentHeader';
import ProductShowDescription from './ProductShowDescription';
import ProductShowReviews from './ProductShowReviews';

class ProductContentContainer extends Component {

    render() {
        const {
            showContent,
            friendlyURL,
            allReviewsByProduct,
            allTestersReview,
            product,
            sumStarMemberReview,
            sumStarTesterReview,
            recommended,
            countAmazing,
            countAverage,
            countPoor,
            countLovedIt,
            countLikedIt,
            countHatedIt,
            countQualityQuestion,
            countImpressionQuestion,
            continueUsingTotal,
            continueUsingYes,
        } = this.props.state
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="la-wc-tabs-wrapper">
                        <div className="wc-tabs-outer">
                            <div className="woocommerce-tabs wc-tabs-wrapper">

                                <ProductShowContentHeader
                                    showContent={showContent}
                                    onShowContent={this.props.onShowContent}
                                    friendlyURL={friendlyURL}
                                    allReviewsByProductQty={allReviewsByProduct.length}
                                    allTestersReview={allTestersReview.length}
                                />

                                {showContent === 'Description' && <ProductShowDescription productDescription={product.ProductDescription} />}

                                {showContent === 'MemberReviews'
                                    && (allReviewsByProduct.length > 0 || allTestersReview.length > 0)
                                    && <ProductShowReviews
                                        productRating={this.props.productRating}
                                        allReviewsByProduct={allReviewsByProduct}
                                        allReviewsByProductQty={allReviewsByProduct.length}
                                        allTestersReview={allTestersReview}
                                        allTestersReviewQty={allTestersReview.length}
                                        sumStarMemberReview={sumStarMemberReview}
                                        sumStarTesterReview={sumStarTesterReview}
                                        recommended={recommended}
                                        countAmazing={countAmazing}
                                        countAverage={countAverage}
                                        countPoor={countPoor}
                                        countLovedIt={countLovedIt}
                                        countLikedIt={countLikedIt}
                                        countHatedIt={countHatedIt}
                                        countQualityQuestion={countQualityQuestion}
                                        countImpressionQuestion={countImpressionQuestion}
                                        continueUsingTotal={continueUsingTotal}
                                        continueUsingYes={continueUsingYes}
                                    />}

                            </div>
                        </div>

                        <ProductRelatedContainer productId={this.props.productId} />

                    </div>
                </div>
            </div>
        )
    }
}

ProductContentContainer.defaultProps = {
    productRelatedList: [],
    state: {},
}

export default ProductContentContainer;
