import React, { Component } from 'react';

import CategoryItemComponent from './CategoryItemComponent';
import { getAllCategory } from '../../../service/Category';

class CategoryListContainer extends Component {

    state = {
        categoryList: [],
        displayCategories: window.innerWidth > 900? true : false,
    }

    componentDidMount = () => {
        getAllCategory()
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                if (res.ok)
                    this.setState({
                        categoryList: json,
                    })
            })
    }

    onCategoryClick = (childrenProps) => {
        this.props.onCategoryClick(childrenProps);
    }

    render() {
        return (
            <div className="widget woocommerce widget_product_categories accordion-menu" >
                <h3 className="lazyra-widget-title">
                <span style={{ fontFamily: 'Poppins', color: '#343538', fontWeight: 'bold' }}>CATEGORIES
                    {window.innerWidth < 900 && <span
                            className="float-right ml-3" style={{ cursor: 'pointer' }}
                        onClick={() =>
                            this.setState({ displayCategories: !this.state.displayCategories })
                        }>
                        <i className={this.state.displayCategories ? "mm-icon fas fa-caret-up" : "mm-icon fas fa-caret-down"}
                            style={{ fontSize: '30px' }}>
                        </i>
                    </span>}
                </span>
                </h3>
                {this.state.displayCategories && <ul className="product-categories menu" >
                    <CategoryItemComponent categoryList={this.state.categoryList} onCategoryClick={this.onCategoryClick} friendlyURL={this.props.friendlyURL} />
                </ul>}
            </div>
        )
    }
}

CategoryListContainer.defaultProps = {
    categoryList: [],
    friendlyURL: '',
}

export default CategoryListContainer;