import React, { Component } from 'react';

import Content from '../Content';

class Container extends Component {

	render() {
		return (
			<div className="lazyra-page-container page-container-dashboard row-fluid">
				<Content />
			</div>
		);

	}
}

export default Container;
