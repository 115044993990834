import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/ManufacturerContact`;

export const getAllManufacturerContact = () => {
  return fetch(
    endpoint,
    Object.assign(
      {
        method: 'GET'
        //body: JSON.stringify()
      },
      getFetchOpts()
    )
  );
};

export const getAllManufacturerContactPaged = tableModel => {
  let url = `${endpoint}/GetAllPaged/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(tableModel)
      },
      getFetchOpts()
    )
  );
};

export const getManufacturerContact = id => {
  let url = `${endpoint}/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const getManufacturerContactByManufacturerId = id => {
  let url = `${endpoint}/GetManufacturerContactByManufacturerId/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const saveManufacturerContact = ManufacturerContact => {
  let url = endpoint;
  let method = 'POST';

  if (ManufacturerContact.Id && ManufacturerContact.Id > 0) {
    url += `/${ManufacturerContact.Id}`;
    method = 'PUT';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(ManufacturerContact)
      },
      getFetchOpts()
    )
  );
};

export const deleteManufacturerContact = id => {
  let url = endpoint;
  let method = 'POST';

  if (id && id > 0) {
    url += `/${id}`;
    method = 'DELETE';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(id)
      },
      getFetchOpts()
    )
  );
};

export const login = model => {
  let url = endpoint;
  let method = 'POST';

  url += '/Login';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(model)
      },
      getFetchOpts()
    )
  );
};

export const forgotPassword = client => {
  let url = `${endpoint}/ForgotPassword`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(client)
      },
      getFetchOpts()
    )
  );
};

export const resetPassword = client => {
  let url = `${endpoint}/ResetPassword`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(client)
      },
      getFetchOpts()
    )
  );
};

export const editPassword = client => {
  let url = `${endpoint}/ChangePassword`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(client)
      },
      getFetchOpts()
    )
  );
};

export const getManufacturerContactToken = () => {
  let url = `${endpoint}/GetToken`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
        //body: JSON.stringify()
      },
      getFetchOpts()
    )
  );
};
