import React from 'react';

import AliceCarouselComponent from './AliceCarouselComponent';
import ProductInformation from './ProductInformation';

const ProductDetailContainer = ({ productImages, product, productRating }) => {
  return (
    <div className="row la-single-product-page vc_row">
      <div className="col-xs-12 col-sm-6">
        <AliceCarouselComponent productImages={productImages} imagePath="product" videoId={product.ProductConfiguration.YoutubeId} />
      </div>

      <div className="col-xs-12 col-sm-6 p-right product--summary">
        <ProductInformation product={product} productRating={productRating} />
      </div>
    </div>
  );
};

export default ProductDetailContainer;
