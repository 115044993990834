import React, { useState, useEffect } from 'react';
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import { withAlert } from 'react-alert';
import swal from 'sweetalert';
import { withCookies } from 'react-cookie';

import ReviewVotes from './ProductShowReviewVotes';
import { deleteReviewWebSiteRange } from 'service/ReviewWebSite';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

const time = moment(new Date()).format('YYYYMMDDHHmmss');

const ProductShowReviewsMember = props => {
  const [allReviewsByProductData, setAllReviewsByProductData] = useState([]);

  useEffect(() => {
    setAllReviewsByProductData(props.allReviewsByProduct)
  }, [props.allReviewsByProduct]);

  const handleDeleteReview = ({ CommunityMemberId, ProductId }) => {
    swal({
      title: 'Please confirm',
      text: 'Do you want to delete this review?',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(result => {
      if (result) {
        deleteReviewWebSiteRange({ CommunityMemberId, ProductId })
          .then(res => res.json().then(json => ({ res, json })))
          .then(({ res, json }) => {
            if (!json.success) props.alert.error(json.msg);
            else {
              props.alert.success(json.msg);
              setAllReviewsByProductData(allReviewsByProductData.filter(item => item[0].CommunityMemberId !== CommunityMemberId));
            }
          });
      }
    });
  };
  return allReviewsByProductData.map((item, index) => (
    <div key={index} className="wpb_column vc_column_container allReviews col-sm-12">
      <div className="row">
        <div className="wpb_column vc_column_container col-sm-3">
          {/* TODO: change later to member and tester review */}
          <div style={{ fontFamily: 'Poppins' }}>
            PTPA Member Review
          </div>

          <img
            src={
              item[0].CommunityMember.ProfileImage !== null && item[0].CommunityMember.ProfileImage !== ''
                ? `${STORAGE_URL}member/${item[0].CommunityMember.ProfileImage}?${time}`
                : item[0].CommunityMember.CommunityMemberGenderTargetId === 1
                ? '/assets/img/FemaleProfile.png'
                : item[0].CommunityMember.CommunityMemberGenderTargetId === 2
                ? '/assets/img/MaleProfile.png'
                : '/assets/img/OtherProfile.png'
            }
            style={{ width: '100px', borderRadius: '50%' }}
            alt=""
          />
          <br />

          <div>
            <span className="product_item--title">
              <b style={{ fontFamily: 'Poppins' }}>{item[0].CommunityMember.FirstName}</b>
            </span>
          </div>

          {item[0].CommunityMember.State && (
            <div style={{ fontFamily: 'Poppins' }}>
              {item[0].CommunityMember.City}
              <span>/</span>
              {item[0].CommunityMember.State.StateName}
            </div>
          )}

          <div>
            <StarRatings
              id="Rating"
              rating={
                item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 2).length > 0
                  ? parseInt(item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 2)[0].Answer)
                  : 0
              }
              starRatedColor="orange"
              numberOfStars={5}
              name="rating"
              starDimension="25px"
              starSpacing="2px"
            />
          </div>

          {item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 5).length > 0 && (
            <div style={{ fontFamily: 'Poppins' }}>{item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 5)[0].Answer}</div>
          )}

          {item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 5).length === 0 &&
            item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 2).length > 0 && (
              <div style={{ fontFamily: 'Poppins' }}>
                {item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 2)[0].Answer === '4' ||
                item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 2)[0].Answer === '5'
                  ? 'Highly Recommended'
                  : 'Not Recommended'}
              </div>
            )}

          {item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 6).length > 0 && (
            <div style={{ fontFamily: 'Poppins' }}>
              <span>Used for: </span>
              {item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 6)[0].Answer.toLowerCase()}
            </div>
          )}

          {props.cookies.get('typeUser') === 'Administrator' && (
            <span id={index} onClick={() => handleDeleteReview(item[0])} title="Remove" className="mouseDelete">
              <i className="fa fa-trash"></i>
            </span>
          )}
        </div>

        <div className="wpb_column vc_column_container col-sm-9">
          <div>
            <div className="titleAnwserReview">
              <span className="product_item--title">
                <b style={{ fontFamily: 'Poppins' }}>
                  {item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 1)[0]
                    ? item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 1)[0].Answer
                    : ''}
                </b>
              </span>
            </div>
          </div>
          <div style={{ fontFamily: 'Poppins' }}>
            {item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 9)[0]
              ? item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 9)[0].Answer
              : ''}
          </div>

          {item.filter(reviewTitle => reviewTitle.ReviewQuestionId === 14).length > 0 && (
            <img
              className="mt-3 img-fluid"
              src={`${STORAGE_URL}review/${item.find(review => review.ReviewQuestionId === 14).Answer}?${time}`}
              style={{ width: '200px', height: 'auto' }}
              alt=""
            />
          )}
        </div>
      </div>
      <ReviewVotes reviewVotes={review(item) ? review(item).ReviewWebSiteVote : []} authorId={review(item) ? review(item).CommunityMemberId : 0} reviewWebSiteId={review(item) ? review(item).Id : 0} />
    </div>
  ));
};

const review = item => item.filter(reviewTitle=>reviewTitle.ReviewQuestionId === 9)
 ? item.filter(reviewTitle=>reviewTitle.ReviewQuestionId === 9)[0]
 : null;

export default withAlert(withCookies(ProductShowReviewsMember));
