import React, { Component } from 'react';
import moment from 'moment';

import { STORAGE_URL } from '../../../util/MyConstantsFile';
import { getProductByUrlFriendly } from '../../../service/Product';
import { getListIdProductPaged } from '../../../service/ReviewWebSite';
import { getListIdProduct } from '../../../service/Review';
import ProductContainer from '../_Components/ProductContainer';
import ProductContentContainer from '../_Components/ProductContentContainer';
import { saveCommunityMemberProductVisit } from '../../../service/CommunityMemberProductVisit';
import ProductDetailContainer from '../_Components/ProductDetailContainer';
import {BASE_URL} from '../../../util/MyConstantsFile';

class Product extends Component {
  state = {
    product: null,
    productImages: [],
    categoryImage: '',
    iconCategory: '',
    allReviewsByProduct: [],
    allTestersReview: [],
    friendlyURL: this.props.match.params.friendlyURL,
    showContent: 'MemberReviews',
    page: 0,
    pageSize: 80,
    filtered: [],
    pages: null,
    countAmazing: 0,
    countAverage: 0,
    countPoor: 0,
    countLovedIt: 0,
    countLikedIt: 0,
    countHatedIt: 0,
    sumStarMemberReview: 0,
    sumStarTesterReview: 0,
    recommended: 0,
    countQualityQuestion: 0,
    countImpressionQuestion: 0,
    continueUsingTotal: 0,
    continueUsingYes: 0,
    styleCategoryImage:
      window.innerWidth > 900
        ? { position: 'absolute', textAlign: 'center', left: '0', right: '0', top: '30px' }
        : { position: 'absolute', textAlign: 'center', left: '0', right: '0', top: '-15px' }
  };

  componentDidMount() {
    let friendlyURL = this.state.friendlyURL;
    if (friendlyURL) {
      window.location.href = `${BASE_URL.productSingle}${friendlyURL.toLowerCase()}`;
    }
  }
  componentDidMountOLD() {
    let friendlyURL = this.state.friendlyURL;
    if (friendlyURL) {
      getProductByUrlFriendly(friendlyURL)
        .then(res => res.json().then(json => ({ res, json })))
        .then(({ res, json }) => {
          const obj = { ProductId: json.Id };
          saveCommunityMemberProductVisit(obj);

          var allProductImages = [];

          if (json.LargeImage) {
            if (json.ProductPhoto.length > 0) {
              // allProductImages.push(json.LargeImage);
              json.ProductPhoto.forEach(element => {
                if (element.Status) allProductImages.push(element.Photo);
              });
            } else allProductImages.push(json.LargeImage);
          }

          getListIdProduct(json.Id)
            .then(res2 => res2.json().then(json2 => ({ res2, json2 })))
            .then(({ res2, json2 }) => {
              this.setState(
                {
                  product: json,
                  productImages: allProductImages,
                  filtered: { ProductId: json.Id },
                  categoryImage: `${STORAGE_URL}category/${json.ProductCategory[0].Category.CategoryLargeImage}?${moment(new Date()).format(
                    'YYYYMMDDHHmmss'
                  )}`,
                  iconCategory: `${STORAGE_URL}category/${json.ProductCategory[0].Category.IconCategory}?${moment(new Date()).format(
                    'YYYYMMDDHHmmss'
                  )}`,
                  allTestersReview: json2.ReviewData,
                  sumStarTesterReview: json2.SumStarTesterReview
                },
                () => {
                  this.fetchData(this.state);
                }
              );
            });
        });
    }
  }

  fetchData = (state, instance) => {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(this.state, state.pageSize, state.page, state.filtered).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        allReviewsByProduct: res.rows,
        pages: res.pages,
        countAmazing: res.countAmazing,
        countAverage: res.countAverage,
        countPoor: res.countPoor,
        countLovedIt: res.countLovedIt,
        countLikedIt: res.countLikedIt,
        countHatedIt: res.countHatedIt,
        sumStarMemberReview: res.sumStarMemberReview,
        recommended: res.recommended,
        countQualityQuestion: res.countQualityQuestion,
        countImpressionQuestion: res.countImpressionQuestion,
        continueUsingTotal: res.continueUsingTotal,
        continueUsingYes: res.continueUsingYes
      });
    });
  };

  requestData = (currentState, pageSize, page, filtered) => {
    return new Promise((resolve, reject) => {
      // You can retrieve your data however you want, in this case, we will just use some local data.
      var tableModal = {
        QuantityPerPage: pageSize,
        Page: page + 1,
        ProductId: filtered.ProductId
      };

      getListIdProductPaged(tableModal)
        .then(res => res.json().then(json => ({ res, json })))
        .then(({ res, json }) => {
          let filteredData = json;
          // You must return an object containing the rows of the current page, and optionally the total pages number.
          const result = {
            rows: filteredData.group,
            pages: filteredData.quantity ? filteredData.Quantity : 0,
            countAmazing: filteredData.Amazing,
            countAverage: filteredData.Average,
            countPoor: filteredData.Poor,
            countLovedIt: filteredData.LovedIt,
            countLikedIt: filteredData.LikedIt,
            countHatedIt: filteredData.HatedIt,
            sumStarMemberReview: filteredData.SumStarMemberReview,
            recommended: filteredData.Recommended,
            countQualityQuestion: filteredData.Amazing + filteredData.Average + filteredData.Poor,
            countImpressionQuestion: filteredData.LovedIt + filteredData.LikedIt + filteredData.HatedIt,
            continueUsingTotal: filteredData.ContinueUsingTotal,
            continueUsingYes: filteredData.ContinueUsingYes
          };
          resolve(result);
        });
    });
  };

  onShowContent = childrenProps => {
    this.setState({ showContent: childrenProps });
  };

  render() {
    let productRating = 5;
    if (this.state.allReviewsByProduct.length + this.state.allTestersReview.length > 0)
      productRating =
        (this.state.sumStarMemberReview + this.state.sumStarTesterReview) /
        (this.state.allReviewsByProduct.length + this.state.allTestersReview.length);

    return (
      <div>
        {this.state.product && (
          <ProductContainer
            categoryImage={this.state.categoryImage}
            iconCategory={this.state.iconCategory}
            styleCategoryImage={this.state.styleCategoryImage}
            categoryName={this.state.product.ProductCategory[0].Category.CategoryName}
          >
            <main className="col-md-12 site-content">
              <div className="site-content-inner">
                <div className="lazyra-page-content">
                  <div className="la-p-single-wrap product thumb-has-effect prod-rating-off">
                    {this.state.product && (
                      <ProductDetailContainer product={this.state.product} productImages={this.state.productImages} productRating={productRating} />
                    )}

                    <div className="vc_row-full-width vc_clearfix" />

                    <ProductContentContainer
                      productId={this.state.product.Id}
                      onShowContent={this.onShowContent}
                      state={this.state}
                      productRating={productRating}
                    />
                  </div>
                </div>
              </div>
            </main>
          </ProductContainer>
        )}
      </div>
    );
  }
}

export default Product;
