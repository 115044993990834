//import React, { useState, useEffect } from 'react';
import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import './product-show-review-votes.scss';
import {saveReviewWebSiteVote} from '../../../service/ReviewWebSite';
import {BASE_URL} from '../../../util/MyConstantsFile';

const ProductShowReviewVotes = props => {
  const [currentState, setVoteState] = useState({
    reviewVotes:props.reviewVotes,
    promptLogin:false
  });

  const myId = parseInt(props.cookies.get('gtmc'));
/*
  useEffect(() => {
    setVoteState({reviewVotes:props.reviewVotes,promptLogin:false});
  }, [currentState.reviewVotes,currentState.promptLogin]);
*/
  const {reviewVotes,promptLogin} = currentState;
  const toggleVote = (newVote)=>{

    if(props.authorId === myId)
      return;

    const indexLastVote = reviewVotes.findIndex((val,i,arr)=>val.CommunityMemberId === myId && !val.Deleted);

    let lastVote = null;
    if(indexLastVote >= 0) {
      lastVote = reviewVotes[indexLastVote].Vote;
      reviewVotes.splice(indexLastVote,1);
    }
    if(indexLastVote<0 || lastVote !== newVote) {
      //console.log('new vote',newVote);
      reviewVotes.push({CommunityMemberId:myId,Vote:newVote});
      saveReviewWebSiteVote(props.reviewWebSiteId,{Vote:newVote})
      .then(response=>response.json().then(json=>({response,json})))
      .then(({r,j})=>console.log(r,j));
    }
    else {
      //console.log('unset');
      saveReviewWebSiteVote(props.reviewWebSiteId,{})
      .then(response=>response.json().then(json=>({response,json})))
      .then(({r,j})=>console.log(r,j));
    }
    setVoteState({reviewVotes});

  };
  const promptLoginState = {reviewVotes:reviewVotes,promptLogin:true};
  return (
      <div className="row my-3">
        <div className="wpb_column vc_column_container col-sm-3">
        </div>
        <div className="wpb_column vc_column_container col-sm-9" style={{ fontFamily: 'Poppins' }}>
          <span className="d-inline-block mr-1">Was this review helpful?</span>
          <button className={"d-inline-block mr-1 btn-like "+(voted(myId,reviewVotes,1) ? " voted ": "")} onClick={myId ? ()=>toggleVote(1) : ()=>setVoteState(promptLoginState)}>
            <span className="mr-2">{reviewVotes.filter(vote=>vote.Vote === 1 && !vote.Deleted).length}</span>
            <span className="fa fa-thumbs-up d-inline-block"></span>
          </button>
          <button className={"d-inline-block mr-1 btn-dislike "+(voted(myId,reviewVotes,0) ? " voted ": "")} onClick={myId ? ()=>toggleVote(0) : ()=>setVoteState(promptLoginState)}>
            <span className="mr-2">{reviewVotes.filter(vote=>vote.Vote === 0 && !vote.Deleted).length}</span>
            <span className="fa fa-thumbs-down d-inline-block"></span>
          </button>
          {promptLogin && <span className="error d-inline-block">Please <a href={BASE_URL.dashboard+"Dashboard/CommunityMember/Login?redirect="+encodeURIComponent(window.location.protocol+"//"+window.location.hostname+window.location.pathname)}>login to vote</a></span>}
        </div>
      </div>
  );
};

const voted = (cmid,votes,voteStatus) => votes.filter(item=>(item.Vote === voteStatus && item.CommunityMemberId === cmid && !item.Deleted)).length > 0;

export default withCookies(ProductShowReviewVotes);
