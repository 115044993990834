import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import Auth from '../../../util/Auth';

const Login = React.lazy(() => import('../Login/Login'));
const Form = React.lazy(() => import('../Login/Form'));
const RewardConfirmation = React.lazy(() => import('../CommunityMember/RewardConfirmation'));
const RewardCongratulation = React.lazy(() => import('../CommunityMember/RewardCongratulation'));
const ReviewProduct = React.lazy(() => import('../Reviews/ReviewProduct'));
const ReviewProductSuccess = React.lazy(() => import('../Reviews/ReviewProductSuccess'));
const ForgotPassword = React.lazy(() => import('../Login/ForgotPassword'));
const UpdatePasswordSecurity = React.lazy(() => import('../CommunityMember/UpdatePasswordSecurity'));
const ResetPassword = React.lazy(() => import('../Login/ResetPassword'));
const CompletedSurvey = React.lazy(() => import('../CommunityMember/CompletedSurvey'));
const JoinTheClub = React.lazy(() => import('../CommunityMember/JoinTheClub'));
const JoinTheClubOffer = React.lazy(() => import('../CommunityMember/JoinTheClubOffer'));
const ConfirmEmail = React.lazy(() => import('../CommunityMember/ConfirmEmail'));
const Survey = React.lazy(() => import('../Survey/Survey'));
const NoMatch = React.lazy(() => import('../../Expresso/_ComponentHelper/NoMatch'));
const Dashboard = React.lazy(() => import('routes/dashboard'));

const SecretRoute = ({ component: Component, cookies, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        cookies.get('token') ? <Component {...props} /> : <Redirect to={`/Dashboard/CommunityMember/Login?redirect=${window.location.pathname}`} />
      }
    />
  );
};

class Content extends Component {
  render() {
    const { cookies } = this.props;

    if (!Auth.getToken() && cookies.get('token')) Auth.authenticateUser(cookies.get('token'));

    return (
      <div className="lazyra-page-content bg-white">
        <div id="portlet-config" className="modal hide">
          <div className="modal-header">
            <button data-dismiss="modal" className="close" type="button" />
            <h3>Widget Settings</h3>
          </div>
          <div className="modal-body"> Widget settings form goes here </div>
        </div>
        <div className="clearfix" />
        <div className="content">
          <Suspense fallback={<div />}>
            <Switch>
              <Route
                path="/Dashboard/CommunityMember/Authvalidation"
                render={props => {
                  if (cookies.get('token')) {
                    window.location.href = window.location.pathname.toLocaleLowerCase().replace('/dashboard/communitymember/authvalidation/', '');
                    return;
                  } else return <Redirect to={`/Dashboard/CommunityMember/Login?redirect=${window.location.pathname}`} />;
                }}
              />
              <Route path="/Dashboard/CommunityMember/Login" render={props => <Login {...props} />} />
              <Route path="/Dashboard/CommunityMember/NewMember" render={props => <Form {...props} />} />
              <SecretRoute path="/Dashboard/CommunityMember/RewardConfirmation" cookies={cookies} component={RewardConfirmation} />
              <SecretRoute path="/Dashboard/CommunityMember/RewardCongratulation" cookies={cookies} component={RewardCongratulation} />
              <SecretRoute path="/Dashboard/CommunityMember/Review/:friendlyURL" cookies={cookies} component={ReviewProduct} />
              <SecretRoute path="/Dashboard/CommunityMember/ReviewSuccess/:friendlyURL" cookies={cookies} component={ReviewProductSuccess} />
              <Route path="/Dashboard/CommunityMember/Forgot-Password" render={props => <ForgotPassword {...props} />} />
              <Route path="/Dashboard/CommunityMember/Update-Password-Security" render={props => <UpdatePasswordSecurity {...props} />} />
              <Route path="/Dashboard/CommunityMember/Reset-Password" render={props => <ResetPassword {...props} />} />
              <SecretRoute path="/Dashboard/CommunityMember/CompletedSurvey" cookies={cookies} component={CompletedSurvey} />
              <SecretRoute path="/Dashboard/CommunityMember/JoinTheClub" cookies={cookies} component={JoinTheClub} />
              <SecretRoute path="/Dashboard/CommunityMember/JoinTheClubOffer" cookies={cookies} component={JoinTheClubOffer} />
              <Route path="/Dashboard/CommunityMember/ConfirmEmail" render={props => <ConfirmEmail {...props} />} />
              <SecretRoute path="/Dashboard/CommunityMember/Survey/:friendlyURL" cookies={cookies} component={Survey} />
              <SecretRoute path="/Dashboard" cookies={cookies} component={Dashboard} />
              <Route render={props => <NoMatch {...props} />} />
            </Switch>
          </Suspense>
        </div>
      </div>
    );
  }
}

export default withCookies(Content);
