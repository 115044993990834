import React from 'react';
import {BASE_URL} from '../../../util/MyConstantsFile';

const LoginContainer = ({ children, productImage = null }) => {
  return (
    <div className="row style-login-container">
      <div className="col-sm-6">{children}</div>
      <div className="style-vc_column_container col-sm-6 text-center d-none d-sm-block">
        <img
          className="img-fluid"
          src={productImage ? productImage : BASE_URL.dashboard+'assets/img/mother-daughter-confetti.png'}
          alt="ptpa"
        />
      </div>
    </div>
  );
};

export default LoginContainer;
