import React, { Component } from 'react';

import Header from '../Header';
import Container from '../Container';
import Footer from '../Footer';

class AppDashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Container />
        <Footer />
      </React.Fragment>
    );
  }
}

export default AppDashboard;
