import React, { Component } from 'react';
import moment from 'moment';
import {BASE_URL} from '../../../util/MyConstantsFile';


class Footer extends Component {
    // constructor(props) {
    // 	super(props);
    // }

    render() {
        return (
            <footer id="colophon" className="style-site-footer">
                <div className="lazyra-footer-top">
                    <div className="style-footer-container">
                        <div className="row">
                            <div className="style-footer-column col-xs-12 col-sm-6 col-md-3">
                                <div className="style-widget">
                                    <a href="http://www.ptpa.com/">
                                        <img width="121" height="35" src="/assets/img/Parent-Tested-Parent-Approved.com-Logo.png" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </a>
                                </div>
                                <div className="text-uppercase style-custom-footer-text style-widget" style={{ fontFamily: 'Poppins' }}>© {moment().format('YYYY')} PTPA MEDIA INC.
                                    </div>
                                <div className="style-widget">
                                    <div className="style-social-media-link style-default">
                                       <a href="https://www.facebook.com/ptpaparents" className="facebook" title="Facebook" target="_blank" rel="noopener noreferrer nofollow" ><i className="fab fa-facebook-f"></i></a>
                                       <a href="https://www.twitter.com/ptpa" className="twitter" title="Twitter" target="_blank" rel="noopener noreferrer nofollow"><i className="fab fa-twitter"></i></a>
                                       <a href="https://www.instagram.com/ptpaparents" className="instagram" title="Instagram" target="_blank" rel="noopener noreferrer nofollow"><i className="fab fa-instagram"></i></a>
                                       <a href="https://www.pinterest.ca/ptpaparents" className="pinterest" title="Pinterest" target="_blank" rel="noopener noreferrer nofollow"><i className="fab fa-pinterest-p"></i></a>
                                       <a href="https://www.tiktok.com/@ptpaparents" className="tiktok" title="Tik Tok" target="_blank" rel="noopener noreferrer nofollow"><i className="fab fa-tiktok"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="style-footer-column d-none d-md-block col-md-2"></div>
                            <div className="style-footer-column col-xs-12 col-sm-3 col-md-2">
                                <div className="text-uppercase style-custom-footer-text style-widget text-md-right">
                                    <h3 className="lazyra-widget-title style-widget text-md-right"><span style={{ fontFamily: 'Poppins' }}>USEFUL LINKS</span></h3>
                                    <div>
                                        <ul>
                                            <li className="style-menu-item style-widget"><a href="https://ptpa.com/how-it-works/" style={{ fontFamily: 'Poppins' }}>How it works</a></li>
                                            <li className="style-menu-item style-widget"><a target="_blank" rel="noopener noreferrer" href="https://ptpa.com/joinnow" style={{ fontFamily: 'Poppins' }}>Join</a></li>
                                            <li className="style-menu-item style-widget"><a target="_blank" href="/dashboard/communitymember/login" style={{ fontFamily: 'Poppins' }}>Login</a></li>
                                            <li className="style-menu-item style-widget"><a href="https://www.ptpa.com/faqs" style={{ fontFamily: 'Poppins' }}>FAQ’s</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="style-footer-column col-xs-12 col-sm-3 col-md-2">
                                <div className="text-uppercase style-custom-footer-text style-widget text-md-right">
                                    <h3 className="lazyra-widget-title style-widget text-md-right"><span style={{ fontFamily: 'Poppins' }}>COMPANY</span></h3>
                                    <div>
                                        <ul>
                                            <li className="style-menu-item style-widget"><a href="https://www.ptpa.com/about-the-ptpa-community" style={{ fontFamily: 'Poppins' }}>About PTPA</a></li>
                                            <li className="style-menu-item style-widget"><a href="https://www.ptpa.com/advertise-with-us" style={{ fontFamily: 'Poppins' }}>Advertise with us</a></li>
                                            <li className="style-menu-item style-widget"><a href="https://ptpa.com/contact-ptpa/" style={{ fontFamily: 'Poppins' }}>Contact Us</a></li>
                                            <li className="style-menu-item style-widget"><a href="https://www.ptpa.com/terms-of-use" style={{ fontFamily: 'Poppins' }}>Terms &amp; Conditions</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="style-footer-column col-xs-12 col-sm-6 col-md-2 hidden-sm">
                                <div className="text-uppercase style-custom-footer-text style-widget text-md-right">
                                    <h3 className="lazyra-widget-title style-widget text-md-right"><span style={{ fontFamily: 'Poppins' }}>PROFILE</span></h3>
                                    <div>
                                        <ul>
                                            <li className="style-menu-item style-widget"><a href={BASE_URL.dashboard+"Dashboard/overview/"} style={{ fontFamily: 'Poppins' }}>My Dashboard</a></li>
                                            <li className="style-menu-item style-widget"><a href="https://ptpa.com/" style={{ fontFamily: 'Poppins' }}>Redeem Rewards</a></li>
                                            <li className="style-menu-item style-widget"><a href="https://ptpa.com/blog/" style={{ fontFamily: 'Poppins' }}>Feature Articles</a></li>
                                            <li className="style-menu-item style-widget"><a href="https://ptpa.com/latest-winners/" style={{ fontFamily: 'Poppins' }}>Winning Products</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


{/*
                            <div className="style-footer-column style-footer-column-5 col-xs-12 col-sm-6 col-md-3 hidden-sm">
                                <div className="style-widget">
                                    <h3 className="lazyra-widget-title"><span style={{ fontFamily: 'Poppins' }}>@PTPAMEDIA</span></h3>
                                    <div className="">
                                        
                                    </div>
                                </div>
                            </div>
*/}
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
