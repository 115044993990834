/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import * as MyConstantsFile from '../../../util/MyConstantsFile';
import Auth from '../../../util/Auth';

class AdministratorSideBar extends Component {
  onClickLogout = () => {
    const { cookies } = this.props;

    cookies.remove('gtmc', { path: '/' });
    cookies.remove('token', { path: '/' });
    cookies.remove('typeUser', { path: '/', domain: 'ptpa.com' });

    Auth.deauthenticateUser();

    window.location.href = '/login';
  };

  render() {
    return (
      <div className="page-sidebar " id="main-menu">
        <div className="page-sidebar-wrapper scrollbar-dynamic" id="main-menu-wrapper">
          {/* <ul>
					<li>
						<Link to="/"><i className="fa fa-home"></i> <span className="title">Dashboard</span></Link>
					</li>
				</ul> */}

          {this.props.customFlag !== 'simple' && (
          <p className="menu-title sm">
            AWARDS PROGRAM
            <span className="pull-right" />
          </p>
          )}

          {this.props.customFlag !== 'simple' && (
          <ul>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-box" /> <span className="title">Submissions</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-poll-h" /> <span className="title">Surveys</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_SURVEYTEMPLATE_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_SURVEYTEMPLATE_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="far fa-star" /> <span className="title">Campaigns</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_CAMPAIGN_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_CAMPAIGN_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-list" /> <span className="title">{MyConstantsFile.PAGE_NAME_CATEGORY}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_CATEGORY_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_CATEGORY_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="far fa-sticky-note" /> <span className="title">Packages</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_PACKAGE_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_PACKAGE_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
          </ul>
          )}

          <p className="menu-title sm">
            AUDIENCE
            <span className="pull-right" />
          </p>

          <ul>
            <li>
              <a href="#">
                {' '}
                <i className="far fa-user-circle" /> <span className="title">Community Search</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_COMMUNITYMEMBER_PATH}>Manager</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_COMMUNITYMEMBER_V2_PATH}>Manager (New Search)</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-briefcase" /> <span className="title">Clients</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_CLIENT_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-user-check" /> <span className="title">Evaluators</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_EVALUATOR_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
          </ul>

          <p className="menu-title sm">
            REVIEWS
            <span className="pull-right" />
          </p>
          <ul>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-comment" /> <span className="title">Reviews</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_REVIEW_ACTIVE_PATH}>Active</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_REVIEW_TRASH_PATH}>Trash</Link>
                </li>
              </ul>
            </li>
          </ul>

          <p className="menu-title sm">
            INCENTIVE PROGRAMS
            <span className="pull-right" />
          </p>

          <ul>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-medal" /> <span className="title">Rewards</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_REWARD_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_REWARD_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-clipboard-check" /> <span className="title">Points Feature</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_POINTFEATURE_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_POINTFEATURE_PATH}>Manager</Link>
                </li>
              </ul>
            </li>

            <li>
              <a href="#">
                {' '}
                <i className="fas fa-poll-h" /> <span className="title">{MyConstantsFile.PAGE_NAME_SOCIALMEDIA}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_ADD_MISSION_PATH}>Add Mission</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_MISSION_PATH}>List Mission</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_MOM_SQUAD_APPROVAL_PATH}>Mom Squad Approval</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_APPROVEPOSTING_PATH}>Posting Approval</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_SOCIALMEDIA_REMOVE_MOM_SQUAD_PATH}>Remove Mom Squad User</Link>
                </li>
              </ul>
            </li>
          </ul>

          <p className="menu-title sm">
            DEMOGRAPHIC CONFIGURATION
            <span className="pull-right" />
          </p>

          <ul>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-flag" /> <span className="title">{MyConstantsFile.PAGE_NAME_COUNTRY}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_COUNTRY_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_COUNTRY_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-map-marker-alt" /> <span className="title">{MyConstantsFile.PAGE_NAME_STATE}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_STATE_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_STATE_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-apple-alt" /> <span className="title">{MyConstantsFile.PAGE_NAME_EDUCATIONGROUP}</span>{' '}
                <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-globe-americas" /> <span className="title">{MyConstantsFile.PAGE_NAME_ETHNICITY}</span>{' '}
                <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_ETHNICITY_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_ETHNICITY_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-money-bill-alt" /> <span className="title">{MyConstantsFile.PAGE_NAME_INCOMEGROUP}</span>{' '}
                <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_INCOMEGROUP_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_INCOMEGROUP_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-ring" /> <span className="title">{MyConstantsFile.PAGE_NAME_MARITALSTATUS}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_MARITALSTATUS_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_MARITALSTATUS_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-business-time" /> <span className="title">{MyConstantsFile.PAGE_NAME_WORKSTATUSGROUP}</span>{' '}
                <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-venus-mars" /> <span className="title">{MyConstantsFile.PAGE_NAME_GENDERTARGET}</span>{' '}
                <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_GENDERTARGET_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_GENDERTARGET_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-child" /> <span className="title">{MyConstantsFile.PAGE_NAME_AGETARGET}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_AGETARGET_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_AGETARGET_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-exclamation" /> <span className="title">{MyConstantsFile.PAGE_NAME_ALLERGY}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_ALLERGY_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_ALLERGY_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-newspaper" /> <span className="title">{MyConstantsFile.PAGE_NAME_HEARDABOUT}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_HEARDABOUT_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_HEARDABOUT_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-futbol" /> <span className="title">{MyConstantsFile.PAGE_NAME_INDICATEACTIVITY}</span>{' '}
                <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="far fa-bell" /> <span className="title">{MyConstantsFile.PAGE_NAME_NOTIFICATIONPREFERENCE}</span>{' '}
                <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
          </ul>

          <p className="menu-title sm">
            ADMIN
            <span className="pull-right" />
          </p>

          <ul>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-user-tie" /> <span className="title">{MyConstantsFile.PAGE_NAME_ADMINISTRATOR}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-user" /> <span className="title">User Profile</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_EDIT_PROFILE_PATH}>Edit</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-dollar-sign" /> <span className="title">Taxes</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_TAX_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_TAX_PATH}>Manager</Link>
                </li>
              </ul>
            </li>

            <li>
              <a onClick={this.onClickLogout} style={{ cursor: 'pointer' }}>
                {' '}
                <i className="fa fa-power-off" /> <span className="title">Log out</span>{' '}
              </a>
            </li>
            <li> </li>
            <li> </li>
            <li> </li>
            <li> </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

export default withCookies(AdministratorSideBar);
