/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

const time = moment(new Date()).format('YYYYMMDDHHmmss');

const ProductItemListComponent = props => {
    return (
        <div className="product_item--inner">
            <div className="product_item--thumbnail">
                <div className="product_item--thumbnail-holder">
                    <Link to={`${props.redirectTo}/${props.item.UrlFriendly}`} style={{ cursor: 'pointer' }}>
                        <img src={props.item.LargeImage ? `${STORAGE_URL}product/${props.item.LargeImage}?${time}` : ""}
                            style={{ minHeight: '150px', maxWidth: '186px !important', display: 'block', marginLeft: 'auto', marginRight: 'auto', width: 'auto', maxHeight: '90%', position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}
                            className="wp-post-image img-fluid" alt={props.item.ProductName} />
                        <div className="item--overlay"></div>
                    </Link>
                </div>
            </div>
            <div className="product_item--info">
                <div className="product_item--info-inner" style={{ height: '80px' }}>
                    <Link to={`${props.redirectTo}/${props.item.UrlFriendly}`} style={{ cursor: 'pointer' }}>
                        <span className="product_item--title" style={{ fontFamily: 'Poppins' }}>
                            <b>
                                {props.item.ProductName}
                            </b>
                        </span>
                    </Link>
                    {/* <br />
                    <span
                        style={{ cursor: 'pointer', fontFamily: 'Poppins' }}>
                        <span className="sku">
                            <Link to={`/Products/manufacturer-${props.item.ManufacturerId}/1`}>MFG: {props.item.Manufacturer.CompanyName}</Link>
                        </span>
                    </span> */}
                </div>
            </div>
        </div>
    )
}

export default ProductItemListComponent;