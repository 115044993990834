import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import qs from 'qs';

import ClientLoginTemplate from '../_ComponentHelper/ClientLoginTemplate';
import LoginEmail from './LoginEmail';
import LoginCoupon from './LoginCoupon';
import { getCouponByCode } from '../../../service/Coupon';

class Login extends Component {
  state = {
    newClient: null,
    couponCode: qs.parse(this.props.location.search.slice(1)).couponcode || qs.parse(this.props.location.search.slice(1)).couponCode
  };

  componentDidMount() {
    if (this.state.couponCode)
      getCouponByCode(this.state.couponCode)
        .then(res => res.json().then(json => ({ res, json })))
        .then(({ res, json }) => {
          if (res.ok) {
            if (json.CouponCode != null) this.setState({ newClient: true });
            else this.setState({ newClient: false });
          }
        });
    else this.setState({ newClient: false });
  }

  onSaveCookie = token => {
    const { cookies } = this.props;

    cookies.set('token', token, { path: '/' });
    cookies.remove('typeUser', { path: '/', domain: 'ptpa.com' });
  };

  renderLogin = () => {
    if (this.state.newClient) {
      return <LoginCoupon couponCode={this.state.couponCode} />;
    } else {
      return <LoginEmail onSaveCookie={this.onSaveCookie} />;
    }
  };

  render() {
    if (this.state.newClient === null) return <div></div>;

    return <ClientLoginTemplate>{this.renderLogin()}</ClientLoginTemplate>;
  }
}

export default withCookies(Login);
