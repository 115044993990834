import React, { Component } from 'react';

import * as MyConstantsFile from '../../../util/MyConstantsFile'
import PopularProductItemListContainer from './PopularProductItemListContainer';
import { getAllCommunityMemberProductVisitAsync } from '../../../service/CommunityMemberProductVisit';

class PopularProductListContainer extends Component {

    state = {
        popularProductList: [],
        displayPopularProducts: window.innerWidth > 900 ? true : false,
    }

    componentDidMount = () => {
        getAllCommunityMemberProductVisitAsync()
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                if (res.ok)
                    this.setState({
                        popularProductList: json,
                    })
            })
    }

    onManufactureSearch = (childrenManufactureSearch) => {
        this.props.onManufactureSearch(childrenManufactureSearch);
    }

    render() {
        return (
            <div className="widget">
                <h3 className="lazyra-widget-title">
                    <span style={{ fontFamily: 'Poppins', color: '#343538', fontWeight: 'bold' }}>POPULAR PRODUCTS
                        {window.innerWidth < 900 && <span
                            className="float-right ml-3" style={{ cursor: 'pointer' }}
                            onClick={() =>
                                this.setState({ displayPopularProducts: !this.state.displayPopularProducts })
                            }>
                            <i className={this.state.displayPopularProducts ? "mm-icon fas fa-caret-up" : "mm-icon fas fa-caret-down"}
                                style={{ fontSize: '30px' }}>
                            </i>
                        </span>}
                    </span>
                </h3>
                {this.state.displayPopularProducts && <div>
                    <div className="woocommerce">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="products products-list products-list-mini grid-items">
                                    <PopularProductItemListContainer popularProductList={this.state.popularProductList}
                                        redirectTo={MyConstantsFile.APP_WEBSITE_PRODUCT_CALL_PATH}
                                        onManufactureSearch={this.onManufactureSearch} />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

export default PopularProductListContainer;