import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/SystemMessage`;


export const getSystemMessage = message => {
	let url = `${endpoint}/${message}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};
