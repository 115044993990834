import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import swal from 'sweetalert';
import { withCookies } from 'react-cookie';

import * as MyConstantsFile from '../../../util/MyConstantsFile';
import Auth from '../../../util/Auth';
import { login, getAdministratorToken } from '../../../service/Administrator';
import { getSystemMessage } from '../../../service/SystemMessage';

import Image from './images/work.jpg';

class MyLogin extends Component {
  state = {
    Email: '',
    Password: ''
  };

  componentDidMount() {
    document.getElementsByTagName('BODY')[0].style.backgroundImage = `url(${Image})`;
  }

  render() {
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting } = this.props;

    return (
      <div className="container">
        <div className="row login-container animated fadeInUp">
          <div className="col-md"></div>
          <div className="col-md-7 tiles white no-padding">
            <div className="p-t-30 p-l-40 p-b-20 xs-p-t-10 xs-p-l-10 xs-p-b-10">
              <h2 className="normal">Sign in to Espresso</h2>
            </div>
            <div className="tiles grey p-t-20 p-b-20 no-margin text-black tab-content">
              <div role="tabpanel" className="tab-pane active" id="tab_login">
                <form className="animated fadeIn validate" onSubmit={handleSubmit}>
                  <div className="row form-row m-l-20 m-r-20 xs-m-l-10 xs-m-r-10">
                    <div className="col-md-6 col-sm-6">
                      <input
                        className="form-control"
                        id="UserName"
                        placeholder="Email"
                        type="email"
                        value={values.UserName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label id="login_username-error" className="error" htmlFor="UserName">
                        This field is required.
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <input
                        className="form-control"
                        id="Password"
                        placeholder="Password"
                        type="password"
                        value={values.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label id="login_pass-error" className="error" htmlFor="Password">
                        This field is required.
                      </label>
                    </div>
                  </div>
                  <div className="row p-t-10 m-l-20 m-r-20 xs-m-l-10 xs-m-r-10">
                    <div className="control-group col-md-10">
                      <div className="pull-left">
                        <button className={isSubmitting ? 'btn btn-primary btn-cons disabled' : 'btn btn-primary btn-cons'} disabled={isSubmitting}>
                          {!isSubmitting ? 'Login' : 'Loading'}
                        </button>
                      </div>
                      <div className="float-right">
                        <Link to="/Login/Forgot-Password">Forgot my password</Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md"></div>
        </div>
      </div>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: props => ({ ...props.model }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props
      .save(values)
      .then(res => res.json().then(json => ({ res, json })))
      .then(({ res, json }) => {
        if (res.ok) {
          if (json.token != null) {
            Auth.authenticateUser(json.token);
            props.onSaveCookie(json.token);

            getAdministratorToken()
              .then(res => res.json().then(json => ({ res, json })))
              .then(({ res, json }) => {
                if (json.Status) {
                  if (json.Level.Name === 'Administrator') {
                    window.location.href = MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_PATH;
                  } else if (json.Level.Name === 'Sales') {
                    window.location.href = MyConstantsFile.APP_ADMIN_PROSPECT_PATH;
                  }
                }
              });
          } else {
            getSystemMessage('loginorpasswordiswrong')
              .then(res => res.json().then(json => ({ res, json })))
              .then(({ res, json }) => {
                swal({
                  title: 'Notice',
                  text: json
                });
              });
          }
        } else {
          getSystemMessage('error')
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
              swal({
                title: 'Notice',
                text: json
              });
            });
        }
        setSubmitting(false);
      })
      .catch(error => {});
  }
});

const MyEnhancedForm = formikEnhancer(MyLogin);

const schema = { Id: 0, UserName: '', Password: '' };

class Login extends Component {
  state = {
    model: Object.assign(schema),
    isRemoving: this.props.isRemoving
  };

  handleSave = values => {
    return login(values).catch(console.log);
  };

  onSaveCookie = token => {
    const { cookies } = this.props;

    cookies.set('token', token, { path: '/' });
    cookies.set('token', token, {path: '/',domain:'.ptpa.com'});
  };

  render() {
    return (
      <div>
        <MyEnhancedForm model={this.state.model} save={this.handleSave} onSaveCookie={this.onSaveCookie} />
      </div>
    );
  }
}

export default withCookies(Login);
