import React, { Component } from 'react';
import { withFormik } from 'formik';
import { getSystemMessage } from '../../../service/SystemMessage';
import swal from 'sweetalert';
import * as Yup from 'yup';

import { resetPassword } from '../../../service/Administrator';

import Image from './images/work.jpg';

class MyLogin extends Component {
  state = {
    Email: '',
    Password: ''
  };

  componentDidMount() {
    document.getElementsByTagName('BODY')[0].style.backgroundImage = `url(${Image})`;
  }

  render() {
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors } = this.props;

    return (
      <div className="container">
        <div className="row login-container animated fadeInUp">
          <div className="col-md"></div>
          <div className="col-md-7 tiles white no-padding">
            <div className="p-t-30 p-l-40 p-b-20 xs-p-t-10 xs-p-l-10 xs-p-b-10">
              <h2 className="normal">It is time to reset your password!</h2>
            </div>
            <div className="tiles grey p-t-20 p-b-20 no-margin text-black tab-content">
              <div role="tabpanel" className="tab-pane active" id="tab_login">
                <div className="row">
                  <div className="form-group col-md-10">
                    <p>We will send to your email the instruction to reset your password.</p>
                  </div>
                </div>
                <form className="animated fadeIn validate" id="loginPasswordForm" onSubmit={handleSubmit}>
                  <div className={touched.Email && errors.Email ? 'form-group has-error' : 'form-group'}>
                    <label className="form-label" htmlFor="Email">
                      Email address *
                    </label>
                    <div className="input-width-icon right">
                      <input type="Email" id="Email" className="form-control" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
                    </div>
                    {errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
                  </div>

                  <div className={touched.Password && errors.Password ? 'form-group has-error' : 'form-group'}>
                    <label className="form-label" htmlFor="Password">
                      New Password *
                    </label>
                    <div className="input-width-icon right">
                      <i></i>
                      <input
                        type="password"
                        id="Password"
                        className="form-control"
                        value={values.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    (Password must contain at least 8 characters and must contain at least one capital letter, one lowercase letter and one numeric
                    character.)
                    <br />
                    {errors.Password && touched.Password && <span className="text-danger">{errors.Password}</span>}
                  </div>

                  <div className={touched.ConfirmPassword && errors.ConfirmPassword ? 'form-group has-error' : 'form-group'}>
                    <label className="form-label" htmlFor="ConfirmPassword">
                      Confirm Password
                    </label>
                    <div className="input-width-icon right">
                      <i></i>
                      <input type="password" id="ConfirmPassword" className="form-control" value={values.ConfirmPassword} onChange={handleChange} />
                    </div>
                    {errors.ConfirmPassword && touched.ConfirmPassword && <span className="text-danger">{errors.ConfirmPassword}</span>}
                  </div>

                  <div className="pull-left">
                    <button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'} disabled={isSubmitting}>
                      {!isSubmitting ? 'Reset Password' : 'Reseting'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md"></div>
        </div>
      </div>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: props => ({ ...props.model }),
  validationSchema: Yup.object().shape({
    Email: Yup.string()
      .required('Email is required!')
      .email('Invalid email address'),
    Password: Yup.string()
      .min(8, 'Password has to be longer than 8 characters!')
      .required('Password is required!')
      .matches(/[a-z]/, 'Lowercase character required!')
      .matches(/[A-Z]/, 'Uppercase character required!')
      .matches(/[0-9]/, 'Numeric character required!'),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref('Password'), null], "Passwords don't match")
      .required('Password is required!')
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props
      .onSave(values)
      .then(res => res.json().then(json => ({ res, json })))
      .then(({ res, json }) => {
        if (json.success) {
          swal({
            title: 'Password reset',
            text: 'Your password was reset.',
            icon: 'success',
            dangerMode: true
          }).then(result => {
            if (result) {
              props.onLoad();
            }
          });
				} else if (json.msg.toLowerCase() === 'error'){
					getSystemMessage("tokeninvalid").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Notice",
								text: json,
								// icon: "warning",
							})
						});
				} else {
					swal({
						title: "Notice",
						text: json.msg,
						icon: "error"
					})
        }
        setSubmitting(false);
      })
      .catch(error => {});
  }
});

const MyEnhancedForm = formikEnhancer(MyLogin);

const now = new Date();

const baseSchema = { Created: now, Updated: now };

const schema = { Id: 0, Email: '', Password: '', ConfirmPassword: '' };

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: this.props.location.search,
      model: Object.assign(schema, baseSchema)
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleLoad = this.handleLoad.bind(this);

    this.state.token = this.state.token.replace('?token=', '');
  }

  handleSave(values) {
    var data = {};
    var register = { Email: values.Email, Password: values.Password };

    data = {
      Register: register,
      CodeResetPassword: this.state.token
    };

    return resetPassword(data).catch(console.log);
  }

  handleLoad() {
    this.props.history.push('/Login');
  }

  render() {
    return (
      <div>
        <MyEnhancedForm model={this.state.model} onSave={this.handleSave} onLoad={this.handleLoad} />
      </div>
    );
  }
}

export default Login;
