import React from 'react';

const CategoryItemComponent = props => {
    return (props.categoryList.map((item) => (
        <li key={item.Id}>
            {props.friendlyURL !== item.UrlFriendly && <span onClick={() => {
                props.onCategoryClick(item.UrlFriendly)
            }}
                style={{ cursor: 'pointer', color: '#4a4a4a', fontFamily: 'Poppins' }} >
                {item.CategoryName}
            </span>}

            {props.friendlyURL === item.UrlFriendly && <strong style={{ cursor: 'default', color: '#4a4a4a', fontFamily: 'Poppins' }} >
                {item.CategoryName}
            </strong>}
        </li>
    )))
}

CategoryItemComponent.defaultProps = {
    categoryList: [],
    friendlyURL: '',
}

export default CategoryItemComponent;