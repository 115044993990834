import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Product`;

export const getAllProduct = tableModel => {
  let url = `${endpoint}/GetAllProduct`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(tableModel)
      },
      getFetchOpts()
    )
  );
};

export const getProductRelated = product => {
  let url = `${endpoint}/GetProductRelated`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(product)
      },
      getFetchOpts()
    )
  );
};

export const getProduct = id => {
  let url = `${endpoint}/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const saveProduct = product => {
  let url = endpoint;
  let method = 'POST';

  if (product.Id && product.Id > 0) {
    url += `/${product.Id}`;
    method = 'PUT';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(product)
      },
      getFetchOpts()
    )
  );
};

export const getProductByToken = tableModel => {
  let url = `${endpoint}/GetByToken`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(tableModel)
      },
      getFetchOpts()
    )
  );
};

export const deleteProduct = id => {
  let url = endpoint;
  let method = 'POST';

  if (id && id > 0) {
    url += `/${id}`;
    method = 'DELETE';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(id)
      },
      getFetchOpts()
    )
  );
};

export const getProductByUrlFriendly = friendlyUrl => {
  let url = `${endpoint}/ListUrlFriendly/${friendlyUrl}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const getProductRelatedList = productRelated => {
  let url = `${endpoint}/ProductRelated/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(productRelated)
      },
      getFetchOpts()
    )
  );
};

export const productWinner = product => {
  let url = `${endpoint}/ProductWinner/`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(product)
      },
      getFetchOpts()
    )
  );
};

export const productPublished = product => {
  let url = `${endpoint}/ProductPublished/`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(product)
      },
      getFetchOpts()
    )
  );
};

export const productReceived = product => {
  let url = `${endpoint}/ProductReceived/`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(product)
      },
      getFetchOpts()
    )
  );
};

export const getProductWebSiteCategory = product => {
  let url = `${endpoint}/GetProductWebSiteCategory`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(product)
      },
      getFetchOpts()
    )
  );
};

export const getAllProductWebSite = product => {
  let url = `${endpoint}/GetAllProductWebSite`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(product)
      },
      getFetchOpts()
    )
  );
};

export const getWebsiteProductRecentWinnerList = modal => {
  let url = `${endpoint}/GetWebsiteProductRecentWinnerList`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(modal)
      },
      getFetchOpts()
    )
  );
};

export const getWebsiteCategoryRecentWinnerList = () => {
  let url = `${endpoint}/GetWebsiteCategoryRecentWinnerList`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const getProductForFilterHeader = id => {
  let url = `${endpoint}/GetProductForFilterHeader/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};
