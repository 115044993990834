
const getEnv = () => {
  if(window.location.href.match('ptpadev.netlify'))
    return 'netlify';
  else if (process.env.NODE_ENV === 'production')
    return 'production';
  else
    return 'development';
}

export const environment = getEnv();
