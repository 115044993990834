import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Category`;

export const getAllCategory = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getCategory = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveCategory = (category) => {
	let url = endpoint;
	let method = 'POST';
	
	if(category.Id && category.Id > 0)
	{
		url += `/${category.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(category)
	}, getFetchOpts()));
};

export const deleteCategory = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};

export const getPerUrlFriendly = urlFriendly => {
	let url = `${endpoint}/GetPerUrlFriendly/${urlFriendly}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};