import React from 'react';
import ProductBannerComponent from './ProductBannerComponent';

const ProductContainer = props => {
    return (
        <div style={props.styleLoading}>
            {props.categoryImage && <ProductBannerComponent
                categoryImage={props.categoryImage}
                iconCategory={props.iconCategory}
                categoryName={props.categoryName} />}

            <div id="main" className="site-main">
                <div className="lazyra-container">
                    <div className="row">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

ProductContainer.defaultProps = {
    categoryImage: '',
    categoryName: '',
    styleCategoryImage: {},
}

export default ProductContainer;