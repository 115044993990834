import React, { Component } from 'react';
import Header from '../Header';
import Container from '../Container';
import Footer from '../../Dashboard/Footer';

class AppWebsite extends Component {

	render() {
		return (
			<React.Fragment>
				<div id="page" className="site bg-white">
					<Header />
					<Container />
					<Footer />
				</div>
			</React.Fragment>
		);
	}
}

export default AppWebsite;