import React, { Component } from 'react';
import ProductItemListComponentEmbed from './ProductItemListComponentEmbed'
import * as MyConstantsFile from '../../../util/MyConstantsFile'

class ProductListComponent extends Component {

    onManufactureSearch = (childrenManufactureSearch) => {
        this.props.onManufactureSearch(childrenManufactureSearch);
    }

    render() {
        return (
            this.props.data.map(item =>
                <div key={item.Id} className="col-md-3 product_item grid-item thumb-has-effect prod-rating-off">
                    <ProductItemListComponentEmbed item={item} redirectTo={MyConstantsFile.APP_WEBSITE_PRODUCT_CALL_PATH} onManufactureSearch={this.onManufactureSearch} />
                </div>
            )
        )
    }
}

ProductListComponent.defaultProps = {
    data: [],
}

export default ProductListComponent;