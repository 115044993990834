import React, { Component } from 'react';

import { getAllCategory } from '../../../service/Category';
import CategoryImageListContainer from '../_Components/CategoryImageListContainer';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class ProductCategory extends Component {

    state = {
        list: [],
    }

    componentDidMount = () => {
        getAllCategory()
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                this.setState({ list: json });
            })
            .catch(console.log);
    }

    onCategoryClick = (childrenCategoryClick) => {
        this.props.history.push(`${(MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH)}/${childrenCategoryClick}/1`)
    }

    render() {
        return (
            <div className="site-main" style={{ paddingTop: '0px' }}>
                <div className="container"
                    style={{ width: '100%' }}
                >
                    <div className="row m-0 p-0">
                        <main id="site-content" className="col-md-12 col-xs-12 site-content m-0 p-0">
                            <div className="site-content-inner">
                                <div className="lazyra-page-content">
                                    <div className="not-active-fullpage">
                                        <div data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid la_row_gap_80 vc_row-no-padding la_fp_slide la_fp_child_section"
                                            style={{ position: 'relative', left: '0px', boxSizing: 'border-box' }}
                                        >
                                            <div className="wpb_column vc_column_container col-sm-12 m-0 p-0">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="vc_row wpb_row vc_inner vc_row-fluid la_row_gap_80 vc_column-gap-15">

                                                            <CategoryImageListContainer data={this.state.list} onCategoryClick={this.onCategoryClick} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><div className="vc_row-full-width vc_clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductCategory;