import React from 'react';

const htmlDecode = (input) => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

const ProductShowDescription = props => {
    return (
        <div className="clearfix woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab active">
            <div className="tab-content">
                <div className="vc_row wpb_row vc_row-fluid la_fp_slide la_fp_child_section">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="js-el la-divider la-unit-responsive" >
                                </div>
                                <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper" style={{ fontSize: '14px', fontFamily: 'Poppins' }} dangerouslySetInnerHTML={{ __html: htmlDecode(props.productDescription) }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ProductShowDescription.defaultProps = {
    productDescription: '',
}

export default ProductShowDescription;