import API_URL from '../config';
import { getFetchOpts, getFetchOptsExcel } from './Common';

const endpoint = `${API_URL}/SurveyTemplate`;

export const getAllSurveyTemplate = () => {
  return fetch(
    endpoint,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const getAllSurveyTemplatePaged = tableModel => {
  let url = `${endpoint}/GetAllPaged/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(tableModel)
      },
      getFetchOpts()
    )
  );
};

export const getSurveyTemplate = id => {
  let url = `${endpoint}/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const getSurveyResults = id => {
  let url = `${endpoint}/GetSurveyResults/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOptsExcel()
    )
  );
};

export const getSurveyResultsQty = id => {
  let url = `${endpoint}/getSurveyResultsQty/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const saveSurveyTemplate = surveyTemplate => {
  let url = endpoint;
  let method = 'POST';

  if (surveyTemplate.Id && surveyTemplate.Id > 0) {
    url += `/${surveyTemplate.Id}`;
    method = 'PUT';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(surveyTemplate)
      },
      getFetchOpts()
    )
  );
};

export const deleteSurveyTemplate = id => {
  let url = endpoint;
  let method = 'POST';

  if (id && id > 0) {
    url += `/${id}`;
    method = 'DELETE';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(id)
      },
      getFetchOpts()
    )
  );
};

export const saveIsShownWebsite = id => {
  let url = `${endpoint}/SaveIsShownWebsite/${id}`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const getSurveyIsShownWebsite = () => {
  let url = `${endpoint}/GetSurveyIsShownWebsite`;
  let method = 'GET';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const getById = id => {
  let url = `${endpoint}/GetById`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(id)
      },
      getFetchOpts()
    )
  );
};
