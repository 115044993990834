import React, { Component } from 'react';

import './styles.css';
import * as MyConstantsFile from 'util/MyConstantsFile';
import ProductListComponent from '../_Components/ProductListComponent';
import { getAllProductWebSite } from 'service/Product';
import ProductEmbedComponent from '../_Components/ProductEmbedComponent';

class AldiProducts extends Component {
  state = {
    sorted: [],
    page: 0,
    pageSize: 200,
    expanded: {},
    resized: [],
    filtered: [],
    loading: true,
    pages: null,
    data: []
  };

  componentDidMount = () => {
    this.fetchData(this.state);
  };

  fetchData = (state, instance) => {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(this.state, state.pageSize, state.page, state.sorted, state.filtered).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        loading: false
      });
    });
  };

  requestData = (currentState, pageSize, page, sorted, filtered) => {
    return new Promise((resolve, reject) => {
      // You can retrieve your data however you want, in this case, we will just use some local data.
      var tableModal = {
        QuantityPerPage: pageSize,
        Page: page,
        Manufacturer: { CompanyName: 'Aldi' }
      };

      if (filtered.length > 0) {
        for (var i = 0; i < filtered.length; i++) {
          var temp = filtered[i].id;
          tableModal[temp] = filtered[i].value;
        }
      }

      if (sorted.length > 0) {
        tableModal.sorted = [];
        for (var j = 0; j < sorted.length; j++) {
          tableModal.sorted.push(sorted[j]);
        }
      }

      getAllProductWebSite(tableModal)
        .then(res => res.json().then(json => ({ res, json })))
        .then(({ res, json }) => {
          if (res.ok) {
            // You must return an object containing the rows of the current page, and optionally the total pages number.
            const result = {
              rows: json.Item2
            };
            resolve(result);
          }
        })
        .catch(console.log);
    });
  };

  onManufactureSearch = childrenManufactureSearch => {
    this.props.history.push(`${MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH}manufacturer-${childrenManufactureSearch}/1`);
  };

  render() {
    return (
      <ProductEmbedComponent>
        <ProductListComponent data={this.state.data} onManufactureSearch={this.onManufactureSearch} />
      </ProductEmbedComponent>
    );
  }
}

export default AldiProducts;
