/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Auth from '../../../util/Auth';

import * as MyConstantsFile from '../../../util/MyConstantsFile';

class SalesSideBar extends Component {
  onClickLogout = () => {
    const { cookies } = this.props;

    cookies.remove('gtmc', { path: '/' });
    cookies.remove('token', { path: '/' });

    Auth.deauthenticateUser();

    window.location.href = '/login';
  };

  render() {
    return (
      <div className="page-sidebar " id="main-menu">
        <div className="page-sidebar-wrapper scrollbar-dynamic" id="main-menu-wrapper">
          <ul>
            {/* <li>
							<Link to="/"><i className="fa fa-home"></i> <span className="title">Dashboard</span></Link>
						</li> */}
            {/* <li><a href="#"> <i className="fa fa-user"></i> <span className="title">Lead</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/Lead">Manager</Link></li></ul></li> */}
            {/* <li><a href="#"> <i className="fa fa-thumbs-o-up"></i> <span className="title">Client</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/Manufacturer">Manager</Link></li></ul></li> */}
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-user" /> <span className="title">{MyConstantsFile.PAGE_NAME_PROFILE}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_SALES_EDIT_PROFILE_PATH}>Edit</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fas fa-user" /> <span className="title">{MyConstantsFile.PAGE_NAME_CUSTOMER}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_CLIENT_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_CLIENT_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fa fa-book" /> <span className="title">{MyConstantsFile.PAGE_NAME_CONTACTS}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_PROSPECT_ADD_PATH}>Add</Link>
                </li>
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_PROSPECT_PATH}>Manager</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                {' '}
                <i className="fa fa-book" /> <span className="title">{MyConstantsFile.PAGE_NAME_PRODUCT}</span> <span className=" arrow" />{' '}
              </a>
              <ul className="sub-menu">
                <li>
                  <Link to={MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_ADD_PATH}>Add</Link>
                </li>
              </ul>
            </li>
            {/* <li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_PRODUCT}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_PRODUCT_PATH}>Manager</Link></li></ul></li> */}
            {/* <li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">Survey</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to="/Product/UploadSurvey">UploadSurvey</Link></li></ul></li>*/}
            <li>
              <a onClick={this.onClickLogout} style={{ cursor: 'pointer' }}>
                {' '}
                <i className="fa fa-power-off" /> <span className="title">Log out</span>{' '}
              </a>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

export default withCookies(SalesSideBar);
