import React from 'react';
import StarRatings from 'react-star-ratings';
import ProductShowTestReviewVotes from './ProductShowTestReviewVotes';

const ProductShowReviewsTester = props => {
    return (
        props.allTestersReview.map((item) => (
            <div key={item.Id} className="wpb_column vc_column_container allReviews col-sm-12">
                <div>
                    <div className="wpb_column vc_column_container col-sm-12">
                        <div style={{ fontFamily: 'Poppins' }}>PTPA Tester Review</div><br />
                        <div>
                            <div className="titleAnwserReview mb-0">
                                <span className="product_item--title">
                                    <b style={{ fontFamily: 'Poppins' }}>
                                        Review
                                </b>
                                </span>
                            </div>
                        </div>
                        <div style={{ fontFamily: 'Poppins' }}>
                            {item.Testimonial}
                        </div>
                        <br />

                        <div>
                            <div className="titleAnwserReview mb-0">
                                <span className="product_item--title">
                                    <b style={{ fontFamily: 'Poppins' }}>
                                        Rating
                                </b>
                                </span>
                            </div>
                        </div>
                        <div className="row my-3">
                          <div className="wpb_column vc_column_container col-sm-3">
                            <StarRatings
                                id="Rating"
                                rating={item.StarRating}
                                starRatedColor="orange"
                                numberOfStars={5}
                                name='rating'
                                starDimension="25px"
                                starSpacing="2px"
                            />
                          </div>
                          <ProductShowTestReviewVotes reviewVotes={item.ReviewVote ? item.ReviewVote : []} reviewId={item.Id} authorId={item.CommunityMemberId} />
                      </div>
                    </div>
                </div>
            </div>
        ))
    )
}

ProductShowReviewsTester.defaultProps = {
    allTestersReview: [],
}

export default ProductShowReviewsTester;
