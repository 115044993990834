import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Survey from 'survey-react';
import swal from 'sweetalert';

import LoginContainer from 'components/Dashboard/_ComponentHelper/LoginContainer';
import { saveCommunityMemberBySurvey } from 'service/CommunityMember';
import { saveCommunityMemberSurvey } from 'service/CommunityMemberSurvey';
import { saveSurveyTemplate, getSurveyIsShownWebsite } from 'service/SurveyTemplate';
import ProductImage from './images/Gleam-Giveaway-Baby.png';

class SurveyNewMembers extends Component {
  state = {
    survey: null,
    surveyCompleted: false,
    terms: false,
    contestTerms: false,
    surveyTemplate: null
  };

  componentDidMount() {
    getSurveyIsShownWebsite()
      .then(res => res.json().then(json => ({ res, json })))
      .then(({ res, json }) => {
        if (json) {
          Survey.StylesManager.applyTheme('default');

          var surveyJs = {
            surveyId: json.SurveyjsKey,
            completedHtml: '<p><h3>Saving...</h3></p>'
          };

          this.setState({ survey: new Survey.Model(surveyJs), surveyTemplate: json });
        }
      })
      .catch(err => {
        swal('No Survey at the moment', 'There is no survey available.', 'info');
      });
  }

  onSurveySave = (survey, options) => {
    const { surveyTemplate } = this.state;
    options.showDataSaving();
    if (!surveyTemplate.SurveyJson) {
      let newSurveyTemplate = surveyTemplate;
      newSurveyTemplate.SurveyJson = JSON.stringify(survey.toJSON().pages[0].elements);
      saveSurveyTemplate(newSurveyTemplate);
    }
    const communityMemberSurveyObj = {
      SurveyTemplateId: surveyTemplate.Id,
      Email: survey.data.Email,
      SurveyResult: JSON.stringify(survey.data)
    };

    let user = {};
    user.FirstName = survey.data.FirstName;
    user.LastName = survey.data.LastName;
    user.Email = survey.data.Email;
    user.DOB = new Date(survey.data.DOB.replace(/-/g, '/'));
    user.City = survey.data.City;
    user.CommunityMemberGenderTargetId = parseInt(survey.data.CommunityMemberGenderTargetId);
    user.CountryId = parseInt(survey.data.CountryId);
    user.StateId = parseInt(survey.data.StateId);
    user.Utm = 'Survey';
    user.UtmMedium = 'survey';
    user.UtmCampaign = 'Survey';
    user.CommunityMemberChildren = [];
    user.TermsAndConditions = new Date();

    for (let i = 0; i < parseInt(survey.data.QtyChildren); i++) {
      const childDOB = new Date(survey.data['ChildDOB' + i].replace(/-/g, '/'));
      const ChildGenderTargetId = parseInt(survey.data['ChildGenderTargetId' + i]);
      user.CommunityMemberChildren.push({ GenderTargetId: ChildGenderTargetId, DOB: childDOB });
    }

    saveCommunityMemberBySurvey(user)
      .then(res => res.json().then(json => ({ res, json })))
      .then(({ res, json }) => {
        if (res.ok) {
          saveCommunityMemberSurvey(communityMemberSurveyObj)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
              if (!json.success) {
                swal({
                  title: 'Confirmed',
                  text: json.msg,
                  icon: 'success'
                }).then(result => {
                  if (result) {
                    this.props.history.push('/Dashboard/CommunityMember/Login');
                  }
                });
              } else {
                options.showDataSavingSuccess();
                this.setState({ surveyCompleted: true });
              }
            });
        } else {
          swal('Error!', 'Something went wrong. Please double check that you did not make any spelling mistakes.', 'error');
          survey.clear(false, true);
        }
      })
      .catch(err => {
        swal('Error!', 'Something went wrong. Please, contact support', 'error');
      });
  };

  render() {
    const { survey, surveyCompleted, terms, contestTerms } = this.state;

    if (!survey) return null;

    return (
      <LoginContainer productImage={ProductImage}>
        {survey && !surveyCompleted && (
          <div>
            <h1>
              <b>
                Complete the survey below for an opportunity to WIN a Baby Essentials Giveaway - Retail Value of $1,000 &amp; a chance to test free
                products!
              </b>
            </h1>
            <h6>You must agree to the terms and conditions below in order to complete the survey!</h6>
            <br />
            <div className="form-group">
              <div className="row align-items-center" style={{ padding: '0px 15px' }}>
                <input
                  type="checkbox"
                  name="Terms"
                  value="Yes"
                  onChange={() => this.setState({ contestTerms: !this.state.contestTerms })}
                  checked={contestTerms}
                />
                <label className="col text-left" htmlFor="Terms">
                  By checking this box I agree to{' '}
                  <a href="https://ptpa.com/gen-rules-and-regulations/" style={{ color: 'blue' }}>
                    the PTPA contest terms and conditions.
                  </a>
                </label>
              </div>

              <div className="row align-items-center" style={{ padding: '0px 15px' }}>
                <input type="checkbox" name="Terms" value="Yes" onChange={() => this.setState({ terms: !this.state.terms })} checked={terms} />
                <label className="col text-left" htmlFor="Terms">
                  By checking this box I agree to{' '}
                  <a href="https://ptpa.com/terms-of-use/" style={{ color: 'blue' }}>
                    PTPA's terms and conditions.
                  </a>
                </label>
              </div>
            </div>
            {terms && contestTerms && <Survey.Survey model={survey} onComplete={this.onSurveySave} />}
          </div>
        )}

        {surveyCompleted && (
          <div className="mb-3 text-center">
            <h2>Thanks for sharing! You are on your way to qualifying as an official Parent Tested Parent Approved tester!</h2>
            <h4 className="mt-4">
              Make sure to log in{' '}
              <Link className="bold text-info" to="/Dashboard/CommunityMember/Login">
                HERE
              </Link>{' '}
              to your account and complete your profile so that we can customize the types of products we send you.
            </h4>
          </div>
        )}
      </LoginContainer>
    );
  }
}

export default SurveyNewMembers;
