import React from 'react'

const NoMatch = () => {

    return (
        <div className="error-wrapper container">
            <div className="row">
                <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-offset-1 col-xs-10">
                    <div className="error-container">
                        <div className="error-main">
                            <div className="error-number"> 404 </div>
                            <div className="error-description"> We seem to have lost you in the clouds. </div>
                            <div className="error-description-mini"> The page your looking for is not here </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoMatch
