/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import {BASE_URL} from '../../../util/MyConstantsFile';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

const time = moment(new Date()).format('YYYYMMDDHHmmss');

const ProductItemListComponentEmbed = props => {
  const productUrl = `${BASE_URL.product.replace(/\/$/,'')}${props.redirectTo}/${props.item.UrlFriendly}`;
  return (
    <div className="product_item--inner">
      <div className="product_item--thumbnail">
        <div className="product_item--thumbnail-holder">
          <a href={productUrl} style={{ cursor: 'pointer' }} target="_parent">
            <img
              src={props.item.LargeImage ? `${STORAGE_URL}product/${props.item.LargeImage}?${time}` : ''}
              style={{
                minHeight: '150px',
                maxWidth: '186px !important',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 'auto',
                maxHeight: '90%',
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                margin: 'auto'
              }}
              className="wp-post-image img-fluid"
              alt={props.item.ProductName}
            />
            <div className="item--overlay" />
          </a>
        </div>
      </div>
      <div className="product_item--info">
        <div className="product_item--info-inner" style={{ height: '80px' }}>
          <a href={productUrl} style={{ cursor: 'pointer' }} target="_parent">
            <span className="product_item--title" style={{ fontFamily: 'Poppins' }}>
              <b>{props.item.ProductName}</b>
            </span>
          </a>
          {/* <br /> */}
          {/* <span style={{ cursor: 'pointer', fontFamily: 'Poppins' }}>
            <span className="sku">
              <a href={`${BASE_URL.product}Products/manufacturer-${props.item.ManufacturerId}/1`} target="_parent">
                MFG: {props.item.CompanyName}
              </a>
            </span>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default ProductItemListComponentEmbed;
