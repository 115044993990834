import React from 'react';
import StarRatings from 'react-star-ratings';

import ProductShowReviewsSummary from './ProductShowReviewsSummary';
import ProductShowReviewsMember from './ProductShowReviewsMember';
import ProductShowReviewsTester from './ProductShowReviewsTester';

const ProductShowReviews = props => {
    return (
        <div className="clearfix woocommerce-Tabs-panel woocommerce-Tabs-panel--reviews panel entry-content wc-tab active">
            <div className="woocommerce-Reviews">
                <div id="comments">
                    <div className="wpb_column vc_column_container allReviews col-sm-12" style={{ paddingBottom: '20px' }}>
                        <div className="wpb_column vc_column_container col-sm-3 summaryReview text-left">
                            <div className="averageReview"></div>
                            <div><h4 className="bold" style={{ fontFamily: 'Poppins' }}>{props.allReviewsByProductQty + props.allTestersReviewQty} customer reviews</h4></div>
                            <div className="row justify-content-start m-0 p-0 inline-item" style={{ lineHeight: '0px' }}>
                                <StarRatings
                                    id="Rating"
                                    rating={props.productRating}
                                    starRatedColor="orange"
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="25px"
                                    starSpacing="2px"
                                />
                                <p className="ml-1 mt-3">{(Math.round(props.productRating * 100) / 100).toFixed(2)} out of 5 stars</p>
                            </div>
                            <h5 style={{ fontSize: '15px' }}>{props.recommended + props.allTestersReviewQty} of {props.allReviewsByProductQty + props.allTestersReviewQty} reviewers recommend this</h5>
                        </div>

                        <div className="col-sm-9">
                            <ProductShowReviewsSummary
                                countAmazing={props.countAmazing}
                                countAverage={props.countAverage}
                                countPoor={props.countPoor}
                                countLovedIt={props.countLovedIt}
                                countLikedIt={props.countLikedIt}
                                countHatedIt={props.countHatedIt}
                                allTestersReviewQty={props.allTestersReviewQty}
                                countQualityQuestion={props.countQualityQuestion}
                                countImpressionQuestion={props.countImpressionQuestion}
                                continueUsingTotal={props.continueUsingTotal}
                                continueUsingYes={props.continueUsingYes}
                            />
                        </div>
                    </div>

                    <div>
                        <ProductShowReviewsMember allReviewsByProduct={props.allReviewsByProduct} />
                    </div>

                    {props.allTestersReviewQty > 0 && <div className="text-center mb-5">
                        <img src="/assets/img/PTPAClubReviewsBanner.png" alt="" />
                    </div>}

                    <div>
                        <ProductShowReviewsTester allTestersReview={props.allTestersReview} />
                    </div>
                </div>
            </div>
        </div>
    )
}

ProductShowReviews.defaultProps = {
    allReviewsByProductQty: 0,
    allTestersReviewQty: 0,
    countAmazing: 0,
    countAverage: 0,
    countPoor: 0,
    countLovedIt: 0,
    countLikedIt: 0,
    countHatedIt: 0,
    countQualityQuestion: 0,
    countImpressionQuestion: 0,
    sumStarMemberReview: 0,
    sumStarTesterReview: 0,
    recommended: 0,
    continueUsingTotal: 0,
    continueUsingYes: 0,
}

export default ProductShowReviews;
