
import React from 'react'

const ClientLoginTemplate = props => {
    return (
        <div className="container">
            <div className="row login-container">
                <div className="col-md-5 offset-md-1">
                    <p><img className="img-fluid" src={'/assets/img/ptpaImage.png'} alt="ptpaImage" /></p>
                    <p className="col no-padding ptpa-image-text">
                        NOTE: This area is NOT public. Please ensure that once you login, you confirm your profile with PTPA in order to be able to submit products for review.
               </p>
                    <p>
                        © 2019 PTPA Media Inc. All rights reserved.
            </p>
                </div>
                <div className="col-md-5">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default ClientLoginTemplate