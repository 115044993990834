import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/CommunityMemberSurvey`;

export const saveCommunityMemberSurvey = communityMemberSurvey => {
  let url = endpoint;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMemberSurvey)
      },
      getFetchOpts()
    )
  );
};
