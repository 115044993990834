import React from 'react';

const LoadingPage = () => {
    return (
        <div className="text-center" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <img src="/assets/img/big-loading-gif.gif" style={{ width: '100px' }} alt="Loading" /> <br />
            <span style={{ fontSize: '30px' }}>Loading...</span>
        </div>
    )
}

export default LoadingPage;