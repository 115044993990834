import Auth from '../util/Auth';

export const getFetchOpts = () => ({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Bearer ${Auth.getToken()}`,
    'cache-control': 'no-cache'
  }
});

export const getFetchOptsCookie = () => ({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Bearer ${getCookie('token')}`,
    'cache-control': 'no-cache'
  }
});

export const getFetchOptsExcel = () => ({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Bearer ${Auth.getToken()}`,
    'cache-control': 'no-cache'
  },
  responseType: 'arraybuffer'
});


function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}
