import React, { Component } from 'react';
import ResponsiveMenu from 'react-responsive-navbar';
import styled from 'styled-components';
import {BASE_URL} from '../../../util/MyConstantsFile';

import menuIcon from './images/menu-icon.svg';
import './index.scss';

const StyleSiteHeader = styled.div`
  @media (max-width: 991px) {
    .style-site-header {
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      background-color: #fff;
      .style-header-component-inner {
        padding-bottom: 0px;
      }
    }
  }
`;
class Header extends Component {
  render() {
    return (
      <div className="style-header bg-white custom-margin-mobile">
        <StyleSiteHeader>
          <header className="style-site-header">
            <div className="style-header-component-inner">
              <div className="style-site-main-nav style-clearfix">
                <div className="small-screen-logo text-left mb-4 ml-4">
                  <a href="https://www.ptpa.com/">
                    <span className="mm-text">
                      <img src="/assets/img/Parent-Tested-Parent-Approved.com-Logo.png" alt="" />
                    </span>
                  </a>
                </div>
                <ResponsiveMenu
                  menuOpenButton={
                    <img
                      className="menu-icon"
                      src={menuIcon}
                      alt=""
                      style={{ width: '40px', marginRight:'5px', paddingRight: '15px', bottom: '72px', position: 'relative' }}
                    />
                  }
                  menuCloseButton={
                    <img
                      className="menu-icon"
                      src={menuIcon}
                      alt=""
                      style={{ width: '40px', marginRight:'5px', paddingRight: '15px', bottom: '72px', position: 'relative' }}
                    />
                  }
                  changeMenuOn="1225px"
                  largeMenuClassName="style-main-menu style-mega-menu"
                  smallMenuClassName="style-mega-menu text-right mr-3 small-menu"
                  menu={
                    <ul className="p-3" style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                      <li className="p-3">
                        <a href="https://ptpa.com/how-it-works/">
                          <span className="mm-text" style={{ fontFamily: 'Poppins' }}>
                            How it Works
                          </span>
                        </a>
                      </li>
                      <li className="p-3">
                        <a href="https://ptpa.com/winners/">
                          <span className="mm-text" style={{ fontFamily: 'Poppins' }}>
                            Product Winners
                          </span>
                        </a>
                      </li>
                      <li className="p-3">
                        <a href="https://ptpa.com/giveaways/">
                          <span className="mm-text" style={{ fontFamily: 'Poppins' }}>
                            OMG Giveaways
                          </span>
                          <span className="style-tip" style={{ color: '#ffffff', background: '#dd3333', fontFamily: 'Poppins' }}>
                            <span className="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }} />
                            WIN!
                          </span>
                        </a>
                      </li>
                      <li className="p-3">
                        <a href={BASE_URL.product+"categories/"}>
                          <span className="mm-text" style={{ fontFamily: 'Poppins' }}>
                            Products
                          </span>
                        </a>
                      </li>
                      <li className="big-screen-logo">
                        <a href="https://ptpa.com/">
                          <span className="mm-text" style={{ fontFamily: 'Poppins' }}>
                            <img src="/assets/img/Parent-Tested-Parent-Approved.com-Logo.png" alt="" />
                          </span>
                        </a>
                      </li>
                      <li className="p-3">
                        <a href="http://ptpa.com/parent-rewards-program-ptpa-club/">
                          <span className="mm-text" style={{ fontFamily: 'Poppins' }}>
                            Rewards
                          </span>
                          <span className="style-tip" style={{ color: '#ffffff', background: '#dd3333', fontFamily: 'Poppins' }}>
                            <span className="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }} />
                            PTPA.CLUB
                          </span>
                        </a>
                      </li>
                      <li className="p-3">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.ptpamedia.com/forbrands"
                        >
                          <span className="mm-text" style={{ fontFamily: 'Poppins' }}>
                            <i className="mm-icon fa fa-briefcase" />
                            ARE YOU A BUSINESS?
                          </span>
                        </a>
                      </li>
                      <li className="p-3">
                        <a href={BASE_URL.dashboard+"dashboard/overview"}>
                          <span className="mm-text" style={{ fontFamily: 'Poppins' }}>
                            <i className="mm-icon fa fa-lock" />
                            My Account
                          </span>
                        </a>
                      </li>
                    </ul>
                  }
                />
              </div>
            </div>
          </header>
        </StyleSiteHeader>
      </div>
    );
  }
}

export default Header;
