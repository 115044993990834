import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

import Auth from '../../../util/Auth';
import { errorCallback } from '../../../util/Auxiliary';
import { getCouponByCode } from '../../../service/Coupon';
import { getSystemMessage } from '../../../service/SystemMessage';

document.body.style = 'background: #e5e9ec';

class MyLogin extends Component {

    state = {
        CouponCode: '',
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.model !== nextProps.model)
            this.props.resetForm();
    }

    render() {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
        } = this.props;

        return (
            <form className="animated fadeIn validate" id="loginPasswordForm" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group col-md-10">
                        <h6 className="font-weight-bold">
                            <b>
                                Welcome to the PTPA Media Business Login
							</b>
                        </h6>
                        <p>
                            This is where you will access your private PTPA Media personal dashboard.
						</p>
                        <div className="row cupom-field">
                            <input className="form-control col-lg col-md-12 mr-1" id="CouponCode"
                                placeholder="Coupon Code" type="text" value={values.CouponCode} onChange={handleChange} onBlur={handleBlur} />
                            <div className="checkbox check-success col-lg-5 no-padding mt-1 mt-lg-0">
                                <button type="submit" className="btn btn-primary btn-cons float-right"><i className="icon-ok"></i>Confirm</button>
                            </div>
                        </div>
                        {errors.CouponCode && touched.CouponCode && <span className="text-danger">{errors.CouponCode}</span>}
                    </div>
                </div>
            </form>
        );
    }
}

const formikEnhancer = withFormik({
    mapPropsToValues: props => ({ ...props.model }),
    validationSchema: Yup.object().shape({
        CouponCode: Yup.string()
            .required('Coupon is required!'),
    }),
    handleSubmit: (values, { setSubmitting, props }) => {
        props.onSaveCoupon(values)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                if (res.ok) {
                    if (json.CouponCode != null) {
                        Auth.authenticateCoupon(json.CouponCode);
                        Auth.setTypeUser("prospect")
                        window.location.href = MyConstantsFile.APP_ADMIN_CLIENT_COMPLETE_INFORMATION_PATH;
                    } else {
                        getSystemMessage("invalidcoupon").then(res => res.json().then(json => ({ res, json })))
                            .then(({ res, json }) => {
                                window.swal('Notice', json, 'warning');
                            });
                    }
                }
            })
            .catch((error) => {
                setSubmitting(false);
                errorCallback("loginorpasswordiswrong")
            });
        setSubmitting(false);
    },

});

const MyEnhancedForm = formikEnhancer(MyLogin);

const schema = { Id: 0, CouponCode: '' };

class LoginCoupon extends Component {

    state = {
        model: Object.assign(schema),
    }

    componentDidMount() {
        if (this.props.couponCode) {
            const coupon = { CouponCode: this.props.couponCode }
            this.setState({ model: coupon });
        }
    }

    onSaveCoupon = (values) => {
        return getCouponByCode(values.CouponCode)
    }

    render() {
        return (
            <div>
                <MyEnhancedForm model={this.state.model}
                    couponCode={this.props.couponCode}
                    onSaveCoupon={this.onSaveCoupon}
                />
            </div>
        );
    }
}

export default LoginCoupon;