import API_URL from '../config';
import { getFetchOpts, getFetchOptsCookie } from './Common';

const endpoint = `${API_URL}/Review`;

export const getAllReview = () => {
  return fetch(
    endpoint,
    Object.assign(
      {
        method: 'GET'
        //body: JSON.stringify()
      },
      getFetchOpts()
    )
  );
};

export const getReview = id => {
  let url = `${endpoint}/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const saveReview = review => {
  let url = endpoint;
  let method = 'POST';

  if (review.Id && review.Id > 0) {
    url += `/${review.Id}`;
    method = 'PUT';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(review)
      },
      getFetchOpts()
    )
  );
};

export const deleteReview = id => {
  let url = endpoint;
  let method = 'POST';

  if (id && id > 0) {
    url += `/${id}`;
    method = 'DELETE';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(id)
      },
      getFetchOpts()
    )
  );
};

export const getListIdProduct = id => {
  let url = `${endpoint}/ListIdProduct/${id}`;
  let method = 'GET';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const reviewApproved = communityMemberProduct => {
  let url = `${endpoint}/ReviewApproved`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMemberProduct)
      },
      getFetchOpts()
    )
  );
};

export const reviewEdit = communityMemberProduct => {
  let url = `${endpoint}/ReviewEdit`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMemberProduct)
      },
      getFetchOpts()
    )
  );
};
export const saveReviewVote = (reviewWebSiteId,vote) => {

  const url = `${endpoint}/${reviewWebSiteId}/Vote`;
  const method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(vote)
      },
      //getFetchOpts()
			getFetchOptsCookie()
    )
  );
};
