import React from 'react';
import ProductShowReviewsSummaryProgress from './ProductShowReviewsSummaryProgress';

const ProductShowReviewsSummary = props => {
    return (
        <div>
            <div className="wpb_column vc_column_container col-sm-6">
                <div className="titleAnwserReview">
                    <span className="product_item--title" style={{ fontFamily: 'Poppins' }}><b>WOW Factor</b></span>
                </div>

                <ProductShowReviewsSummaryProgress title="Amazing"
                    progress={100 * (props.countAmazing + props.allTestersReviewQty) / (props.countQualityQuestion + props.allTestersReviewQty)}
                    rate={`${((props.countAmazing + props.allTestersReviewQty) / (props.countQualityQuestion + props.allTestersReviewQty) * 100).toFixed(0)}%`}
                />

                <ProductShowReviewsSummaryProgress title="Average"
                    progress={100 * (props.countAverage) / (props.countQualityQuestion + props.allTestersReviewQty)}
                    rate={`${(props.countAverage / (props.countQualityQuestion + props.allTestersReviewQty) * 100).toFixed(0)}%`}
                />

                <ProductShowReviewsSummaryProgress title="Poor"
                    progress={100 * (props.countPoor) / (props.countQualityQuestion + props.allTestersReviewQty)}
                    rate={`${(props.countPoor / (props.countQualityQuestion + props.allTestersReviewQty) * 100).toFixed(0)}%`}
                />
            </div>
            <div className="wpb_column vc_column_container col-sm-6">
                <div className="titleAnwserReview">
                    <span className="product_item--title"><b style={{ fontFamily: 'Poppins' }}>Overall Satisfaction</b></span>
                </div>

                <ProductShowReviewsSummaryProgress title="Recommend"
                    progress={100 * (props.countAmazing + props.allTestersReviewQty) / (props.countQualityQuestion + props.allTestersReviewQty)}
                    rate={`${((props.countAmazing + props.allTestersReviewQty) / (props.countQualityQuestion + props.allTestersReviewQty) * 100).toFixed(0)}%`}
                />
                {/* <ProductShowReviewsSummaryProgress title="Recommended"
                    progress={100 * (props.countLovedIt + props.allTestersReviewQty) / (props.countImpressionQuestion + props.allTestersReviewQty)}
                    rate={`${props.countLovedIt + props.allTestersReviewQty}/${props.countImpressionQuestion + props.allTestersReviewQty}`}
                /> */}

                <ProductShowReviewsSummaryProgress title="Value for Money"
                    progress={props.continueUsingTotal > 0 ? 100 * (props.continueUsingYes) / (props.continueUsingTotal) :
                        100 * (props.countAmazing + props.allTestersReviewQty) / (props.countQualityQuestion + props.allTestersReviewQty)}
                    rate={props.continueUsingTotal > 0 ? `${((props.continueUsingYes) / (props.continueUsingTotal) * 100).toFixed(0)}%` :
                        `${((props.countAmazing + props.allTestersReviewQty) / (props.countQualityQuestion + props.allTestersReviewQty) * 100).toFixed(0)}%`}
                />
                {/* <ProductShowReviewsSummaryProgress title="Continue using"
                    progress={100 * (props.countLikedIt) / (props.countImpressionQuestion + props.allTestersReviewQty)}
                    rate={`${props.countLikedIt}/${props.countImpressionQuestion + props.allTestersReviewQty}`}
                /> */}

                {/* <ProductShowReviewsSummaryProgress title="Hated it"
                    progress={100 * (props.countHatedIt) / (props.countImpressionQuestion + props.allTestersReviewQty)}
                    rate={`${props.countHatedIt}/${props.countImpressionQuestion + props.allTestersReviewQty}`}
                /> */}
            </div>
        </div>
    )
}

ProductShowReviewsSummary.defaultProps = {
    countAmazing: 0,
    countAverage: 0,
}

export default ProductShowReviewsSummary;