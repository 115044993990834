import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/CommunityMember`;

export const getAllCommunityMember = () => {
  return fetch(
    endpoint,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const getAllCommunityMemberPaged = tableModel => {
  let url = `${endpoint}/GetAllPaged/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(tableModel)
      },
      getFetchOpts()
    )
  );
};

export const getCommunityMember = id => {
  let url = `${endpoint}/${id}`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const saveCommunityMember = communityMember => {
  let url = endpoint;
  let method = 'POST';

  if (communityMember.Id && communityMember.Id > 0) {
    url += `/${communityMember.Id}`;
    method = 'PUT';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const saveCommunityMemberGoogle = googleTokenId => {
  let url = `${endpoint}/Google`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(googleTokenId)
      },
      getFetchOpts()
    )
  );
};

export const saveCommunityMemberFacebook = facebookCreds => {
  let url = `${endpoint}/Facebook`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(facebookCreds)
      },
      getFetchOpts()
    )
  );
};

export const deleteCommunityMember = id => {
  let url = endpoint;
  let method = 'POST';

  if (id && id > 0) {
    url += `/${id}`;
    method = 'DELETE';
  }

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(id)
      },
      getFetchOpts()
    )
  );
};

export const deleteCommunityMemberAccount = () => {
  let url = `${endpoint}/CloseAccount`;
  let method = 'DELETE';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const cancelCommunityMemberSubscription = () => {
  let url = `${endpoint}/CancelSubscription`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const filterCommunityMember = communityMember => {
  let url = `${endpoint}/Filter/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const filterPaginationCommunityMember = communityMember => {
  let url = `${endpoint}/FilterPaginate/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const exportCommunityMemberDemographic = communityMember => {
  let url = `${endpoint}/exportCommunityMemberDemographic/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const filterPaginationCommunityMemberV2 = communityMember => {
  let url = `${endpoint}/FilterPaginateV2/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const relations = communityMemberId => {
  let url = `${endpoint}/Relations/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify({Id:communityMemberId})
      },
      getFetchOpts()
    )
  );
};


export const exportCommunityMemberDemographicV2 = communityMember => {
  let url = `${endpoint}/ExportCommunityMemberDemographicV2/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};


export const createMailChimpTagList = communityMember => {
  let url = `${endpoint}/CreateMailChimpTagList/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const login = model => {
  let url = `${endpoint}/Login`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(model)
      },
      getFetchOpts()
    )
  );
};

export const getCommunityMemberToken = () => {
  let url = `${endpoint}/GetToken`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const getPointByToken = () => {
  let url = `${endpoint}/GetPointByToken`;

  return fetch(
    url,
    Object.assign(
      {
        method: 'GET'
      },
      getFetchOpts()
    )
  );
};

export const getCommunityMemberPerProduct = communityMember => {
  let url = `${endpoint}/CommunityMemberPerProduct`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const getCommunityMemberTestOpportunity = tableModel => {
  let url = `${endpoint}/CommunityMemberTestOpportunity`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(tableModel)
      },
      getFetchOpts()
    )
  );
};

export const forgotPassword = communityMember => {
  let url = `${endpoint}/ForgotPassword`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const resetPassword = communityMember => {
  let url = `${endpoint}/ResetPassword`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const changePassword = communityMember => {
  let url = `${endpoint}/ChangePassword`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const confirmEmail = communityMember => {
  let url = `${endpoint}/ConfirmEmail`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const getAllByCommunityMemberProductSurveyPaged = communityMember => {
  let url = `${endpoint}/GetAllByCommunityMemberProductSurveyPaged/`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const saveCommunityMemberBySurvey = communityMember => {
  let url = `${endpoint}/SaveCommunityMemberBySurvey`;
  let method = 'POST';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMember)
      },
      getFetchOpts()
    )
  );
};

export const saveSocialMediaProfiles = communityMemberSocialMedia => {
  let url = `${endpoint}/SaveSocialMediaProfiles`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMemberSocialMedia)
      },
      getFetchOpts()
    )
  );
};

export const approveInstagramPost = id => {
  let url = `${endpoint}/ApproveInstagramPost/${id}`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const approveAllInstagramPost = communityMemberIdList => {
  let url = `${endpoint}/ApproveAllInstagramPost`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method,
        body: JSON.stringify(communityMemberIdList)
      },
      getFetchOpts()
    )
  );
};

export const disapproveInstagramPost = id => {
  let url = `${endpoint}/DisapproveInstagramPost/${id}`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const becomeAffiliate = id => {
  let url = `${endpoint}/BecomeAffiliate`;
  let method = 'PUT';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};

export const getReferredMembers = id => {
  let url = `${endpoint}/GetReferredMembers/${id}`;
  let method = 'GET';

  return fetch(
    url,
    Object.assign(
      {
        method
      },
      getFetchOpts()
    )
  );
};
// syncInstagramFollowers only if Followers>0, because only when Followers>0 means they were approved my PTPA staff
// CommunityMemberSocialMedia should be an array that comes from routes/dashboard/index.js.profileList
export const syncInstagramFollowers = async CommunityMemberSocialMedia => {
  const clone = JSON.parse(JSON.stringify(CommunityMemberSocialMedia));
  const social = clone.filter(({SocialMediaId})=>SocialMediaId===1);
  if(social.length>0 && social[0].Followers>0)
  {
    const handle = social[0].SocialMediaProfile.replace(/\/\s*$/,'').replace(/[?&].*/,'').replace(/.*\//,'').trim();
    if(handle)
    {
      try
      {
        const response = await fetch(`https://www.instagram.com/${handle}/?__a=1`);
        const res = await response.json();
        if(res && res.graphql && res.graphql.user.edge_followed_by && res.graphql.user.edge_followed_by.count && res.graphql.user.edge_followed_by.count !== social[0].Followers)
        {
          social[0].Followers = res.graphql.user.edge_followed_by.count;
          saveSocialMediaProfiles({CommunityMemberSocialMedia:clone})
            .then(res=>{if(!res.ok) throw res;})
            .catch(e=>console.log(e));
        }
      }
      catch(e)
      {
        console.log(e);
      }

    }
  }
  return clone;
};
