import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';

import { forgotPassword } from '../../../service/Administrator';
import { getSystemMessage } from '../../../service/SystemMessage';

import Image from './images/work.jpg';

class MyLogin extends Component {
  state = {
    Email: '',
    Password: ''
  };

  componentDidMount() {
    document.getElementsByTagName('BODY')[0].style.backgroundImage = `url(${Image})`;
  }

  render() {
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors } = this.props;

    return (
      <div className="container">
        <div className="row login-container animated fadeInUp">
          <div className="col-md"></div>
          <div className="col-md-7 tiles white no-padding">
            <div className="p-t-30 p-l-40 p-b-20 xs-p-t-10 xs-p-l-10 xs-p-b-10">
              <h2 className="normal">Forgot your password? Don't worry!</h2>
            </div>
            <div className="tiles grey p-t-20 p-b-20 no-margin text-black tab-content">
              <div role="tabpanel" className="tab-pane active" id="tab_login">
                <div className="row">
                  <div className="form-group col-md-10">
                    <p>We will send to your email the instruction to reset your password.</p>
                  </div>
                </div>
                <form className="animated fadeIn validate" id="loginPasswordForm" onSubmit={handleSubmit}>
                  <div className={touched.Email && errors.Email ? 'form-group has-error' : 'form-group'}>
                    <label className="form-label" htmlFor="Email">
                      Email address *
                    </label>
                    <div className="input-width-icon right">
                      <input type="Email" id="Email" className="form-control" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
                    </div>
                    {errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
                  </div>

                  <div className="pull-left">
                    <button className={isSubmitting ? 'btn btn-primary btn-cons disabled' : 'btn btn-primary btn-cons'} disabled={isSubmitting}>
                      {!isSubmitting ? 'Reset password' : 'Resetting'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md"></div>
        </div>
      </div>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: props => ({ ...props.model }),
  validationSchema: Yup.object().shape({
    Email: Yup.string()
      .required('Email is required!')
      .email('Invalid email address')
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props
      .onSave(values)
      .then(res => res.json().then(json => ({ res, json })))
      .then(({ res, json }) => {
        if (json.success) {
          getSystemMessage('forgetpasswordsendemail')
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
              swal({
                title: 'Notice',
                text: json,
                icon: 'success'
              });
            });
        } else {
          getSystemMessage(json.msg)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
              swal({
                title: 'Notice',
                text: json
              });
            });
        }
        setSubmitting(false);
      })
      .catch(error => {});
  }
});

const MyEnhancedForm = formikEnhancer(MyLogin);

const schema = { Id: 0, Email: '' };

class Login extends Component {
  state = {
    model: Object.assign(schema)
  };

  handleSave = values => {
    var data = {};
    data = { Email: values.Email };

    return forgotPassword(data).catch(console.log);
  };

  render() {
    return (
      <div>
        <MyEnhancedForm model={this.state.model} onSave={this.handleSave} />
      </div>
    );
  }
}

export default Login;
