import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import Auth from '../../../util/Auth';
import SalesSideBar from '../Sidebar/SalesSideBar';
import AdministratorSideBar from '../Sidebar/AdministratorSideBar';
import ManufacturerSideBar from '../Sidebar/ManufacturerSideBar';
import CouponSideBar from '../Sidebar/CouponSideBar';
import Content from '../Content';
import { getAdministratorToken } from '../../../service/Administrator';
import { getManufacturerContactToken } from '../../../service/ManufacturerContact';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuStatus: ''
    };
  }

  componentDidMount() {
    if (Auth.getTypeUser() === 'manufacturer' && Auth.isUserAuthenticated()) {
      getManufacturerContactToken()
        .then(res => res.json().then(json => ({ res, json })))
        .then(({ res, json }) => {
          if (json.Status) {
            this.setState({
              menuStatus: 'Manufacturer'
            });
          }
        })
        .catch(console.log);
    } else if (Auth.getTypeUser() === 'prospect') {
      this.setState({
        menuStatus: 'Prospect'
      });
    } else if (Auth.isUserAuthenticated() && this.state.menuStatus === '') {
      getAdministratorToken()
        .then(res => res.json().then(json => ({ res, json })))
        .then(({ res, json }) => {
          if (json.Status) {
            if (json.Level.Name === 'Administrator') {
              const { cookies } = this.props;

              Auth.setTypeUser('Administrator');
              cookies.set('typeUser', 'Administrator', { path: '/', domain: 'ptpa.com' });
              //cookies.set('typeUser', 'Administrator', { path: '/'});

              this.setState({
                menuStatus: 'Administrator',
                customFlag: json.CustomFlag,
              });
            } else if (json.Level.Name === 'Sales') {
              Auth.setTypeUser('Sales');
              this.setState({
                menuStatus: 'Sales'
              });
            }
          }
        })
        .catch(console.log);
    }
  }

  render() {
    return (
      <div className="page-container row-fluid">
        <span className={this.state.menuStatus === 'Administrator' ? 'blockDisplay' : 'noneDisplay'}>
          <AdministratorSideBar customFlag={this.state.customFlag} />
        </span>

        <span className={this.state.menuStatus === 'Sales' ? 'blockDisplay' : 'noneDisplay'}>
          <SalesSideBar />
        </span>

        <span className={this.state.menuStatus === 'Manufacturer' ? 'blockDisplay' : 'noneDisplay'}>
          <ManufacturerSideBar />
        </span>

        <span className={this.state.menuStatus === 'Prospect' ? 'blockDisplay' : 'noneDisplay'}>
          <CouponSideBar />
        </span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="scrollup">
          Scroll
        </a>

        {/* <Footer /> */}
        <Content />
      </div>
    );
  }
}

export default withCookies(Container);
