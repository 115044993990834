import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Auth from '../../../util/Auth';
import { withCookies } from 'react-cookie';

import * as MyConstantsFile from '../../../util/MyConstantsFile';
import { login } from '../../../service/ManufacturerContact';
import { errorCallback } from '../../../util/Auxiliary';

document.body.style = 'background: #e5e9ec';

class MyLogin extends Component {
  state = {
    UserName: '',
    Password: ''
  };

  render() {
    const { values, handleChange, handleBlur, handleSubmit, touched, errors, isSubmitting } = this.props;

    return (
      <form className="animated fadeIn validate" id="loginPasswordForm" onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-10">
            <h6 className="font-weight-bold">
              <b>Welcome to the PTPA Media Business Login</b>
            </h6>
            <p>This is where you will access your private PTPA Media personal dashboard.</p>
            <input
              className="form-control"
              id="UserName"
              placeholder="Email Address"
              type="text"
              value={values.UserName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.UserName && touched.UserName && <span className="text-danger">{errors.UserName}</span>}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-10">
            <input
              className="form-control"
              id="Password"
              placeholder="Password"
              type="password"
              value={values.Password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.Password && touched.Password && <span className="text-danger">{errors.Password}</span>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-10">
            <div className="checkbox check-success">
              <button className={isSubmitting ? 'btn btn-primary btn-cons disabled' : 'btn btn-primary btn-cons'} disabled={isSubmitting}>
                <i className="icon-ok"></i>
                {!isSubmitting ? 'Login' : 'Loading'}
              </button>
              <div className="float-right">
                <span style={{ cursor: 'pointer' }} onClick={() => (window.location.href = `/Client/Forgot-Password`)}>
                  Forgot my password
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: props => ({ ...props.model }),
  validationSchema: Yup.object().shape({
    UserName: Yup.string().required('UserName is required!'),
    Password: Yup.string().required('Password is required!')
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props
      .onLoginPassword(values)
      .then(res => res.json().then(json => ({ res, json })))
      .then(({ res, json }) => {
        if (json.success) {
          if (json.token != null) {
            props.onSaveCookie(json.token);
            Auth.authenticateUser(json.token);
            Auth.setTypeUser('manufacturer');
            window.location.href = MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_PATH;
          } else props.onDisplayMessage('loginorpasswordiswrong');
        } else props.onDisplayMessage('loginorpasswordiswrong');
      })
      .catch(error => {
        setSubmitting(false);
        errorCallback('loginorpasswordiswrong');
      });
    setSubmitting(false);
  }
});

const MyEnhancedForm = formikEnhancer(MyLogin);

const schema = { Id: 0, UserName: '', Password: '', CouponCode: '' };

class LoginEmail extends Component {
  state = {
    model: Object.assign(schema)
  };

  onLoginPassword = values => {
    var objResult = {};

    objResult.Id = values.Id;
    objResult.UserName = values.UserName;
    objResult.Password = values.Password;

    return login(objResult);
  };

  onSaveCookie = token => {
    const { cookies } = this.props;

    cookies.set('token', token, { path: '/' });
    cookies.remove('typeUser', { path: '/', domain: 'ptpa.com' });
  };

  render() {
    return (
      <div>
        <MyEnhancedForm model={this.state.model} onLoginPassword={this.onLoginPassword} onSaveCookie={this.props.onSaveCookie} />
      </div>
    );
  }
}

export default withCookies(LoginEmail);
